import XoneWebCoreResulset from "../Connection/WebCore/XoneWebCoreResulset";
import XoneGenericException from "../Exceptions/XoneGenericException";
import { XoneMessageKeys } from "../Exceptions/XoneMessageKeys";
import IResultSet from "../Interfaces/IResultSet";
import IXoneObject from "../Interfaces/IXoneObject";
import NumberUtils from "../Utils/NumberUtils";
import ObjUtils from "../Utils/ObjUtils";
import StringUtils from "../Utils/StringUtils";
import TextUtils from "../Utils/TextUtils";
import { XoneApplication } from "./XoneApplication";
import { XoneDataCollection } from "./XoneDataCollection";
import { XoneDataObject } from "./XoneDataObject";

export default class XoneLookupObject {
	private m_ownerApp: XoneApplication;
	private m_ownerColl: XoneDataCollection;

	constructor(ownerApp: XoneApplication, ownerColl: XoneDataCollection) {
		this.m_ownerApp = ownerApp;
		this.m_ownerColl = ownerColl;
	}

	/**
	 * Efectúa la búsqueda de un objeto en la base de datos
	 * @param Sentence			Sentencia SQL que se ejecutará contra la conexión de la colección.
	 * @param KeySearch			Clave simple de búsqueda usada para pasarle a los objetos de búsqueda que la necesiten.
	 * @param Prefiltered		TRUE si la sentencia de acceso ya viene prefiltrada por lo que hay que tener cuidado a la hora de adicionarle los filtros de colección.
	 * @return					Devuelve el objeto si se encuentra en base de datos o NULL si no se encuentra.
	 * @throws Exception
	 */
	public async LookupObject(Sentence: string, KeySearch: string, Prefiltered: boolean): Promise<XoneDataObject> {
		let strKey: string = null,
			str: string;
		let obj: XoneDataObject = null;
		let rs: IResultSet = null;
		let val1: Object = null;
		const FunctionName = "CXoneDataCollection::LookupObject";

		try {
			//
			// Asignar valor a la macro de búsqueda. La sintaxis corre a cuenta del desarrollador
			if (!StringUtils.IsEmptyString(this.m_ownerColl.getLookupMacroName()))
				this.m_ownerColl.setMacro(this.m_ownerColl.getLookupMacroName(), KeySearch);
			if (TextUtils.isEmpty((str = this.m_ownerColl.getAccessString()))) return null; // Cadena vacía
			// // Pasar el prefiltered al prepare
			// let strCmd = this.m_ownerColl.getConnection().PrepareSqlString(Sentence, false, Prefiltered);
			// // Evaluar las macros por si las moscas las hay fuera de los filtros...
			// strCmd = await this.m_ownerColl.EvaluateAllMacros(strCmd);
			//
			// Restaurar el valor de la macro para las otras operaciones
			if (!StringUtils.IsEmptyString(this.m_ownerColl.getLookupMacroName())) this.m_ownerColl.setMacro(this.m_ownerColl.getLookupMacroName(), null);
			//
			// if (m_bDebug)
			// {// Dejar traza del SQL
			//     str = "\r\n" + strCmd + "\r\n";
			//     m_owner.WriteConsoleString(str);
			// }// Dejar traza del SQL
			// A12042503: Mecanismo para registrar un modo debug global en la aplicación.
			// if (this.getIsDebugging() || this.m_ownerColl.getOwnerApp().isDebugMode())
			// 	Utils.DebugLog(Utils.TAG_DATABASE_LOG,"LookupObject: "+strCmd);
			//////strCmd = InsertTop(strCmd, 1);
			const swhere = await this.m_ownerColl.BrowseData.ResolveFilters(false, null, KeySearch);
			const sSort = this.m_ownerColl.getSort();
			this.m_ownerColl.ParseMacros(swhere);
			this.m_ownerColl.ParseMacros(sSort);
			this.m_ownerColl.ParseMacros(str);
			const strCmd = await this.m_ownerColl.EvaluateAllMacros(str, true);
			const data = {
				coll: this.m_ownerColl.getName(),
				macros: this.m_ownerColl.getMacros(),
				where: swhere,
				sort: sSort,
				loadall: false,
				page: {},
			};
			// Amiyares: 8/11/2021, modifico esto para generar datos fake, TODO: necesita refactorización
			/* @ts-ignore */
            if (window.generateTestingData){
                /* @ts-ignore */
				const res = window.generateTestingData(this.m_ownerColl);
                /* @ts-ignore */
				rs = new XoneWebCoreResulset(this.m_ownerColl.getConnection().GetCurrentConnection(), res);
                /* @ts-ignore */
				rs.m_data = res;
                /* @ts-ignore */
				rs.m_keys = Object.keys(res[0]);
                /* @ts-ignore */
				rs.m_maxRows = res.length;
            }
			else if (
				null ==
				(rs = await this.m_ownerColl.getConnection().CreateRecordsetAsync(strCmd, { ...{ count: false, page: { start: 0, length: 2 } }, ...data }))
			) {
				// Error
				// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
				////throw new Exception("XoneDataCollection::LookupObject ha fallado. Error ejecutando la sentencia de acceso a los datos.");
				let sb = this.m_ownerColl.GetMessage(XoneMessageKeys.SYS_MSG_COLL_LOOKUPOBJFAIL_02, "{0} failed. Error executing data access query.");
				sb = sb.replace("{0}", FunctionName);
				throw new XoneGenericException(-5634, sb);
			} // Error
			//
			// Ver si ha encontrado algo
			if (await rs.next()) {
				// Lo ha encontrado
				//
				// Comprobar la cantidad de records que se encontraron
				if (!this.m_ownerColl.strPk.contains(",")) {
					// Una sola
					strKey = "";
					// M10052401:	Modificaciones en la forma de acceso a bases de datos SQLite.
					// K11010501:	Modificaciones para la versión 1.5 de Android.
					val1 = rs.getValue(this.m_ownerColl.strPk, this.m_ownerColl.Options.nPkType);
					// Los datos de tipo VT_DECIMAL tienen que convertirse a LONG para que no haya
					// ambiguedades con las claves
					if (!this.m_ownerColl.Options.bStringPk) val1 = NumberUtils.SafeToInt(val1);
					strKey = this.m_ownerColl.BrowseData.DevelopObjectValue(val1);
				} // Una sola
				else {
					// Es múltiple, desarrollar este valor
					// TODO Ver si las claves múltiples serán usadas o no... a fin de cuentas eso no lo usa nadie en otras plataformas.
					/*
                    strKey = "";
                    str1 = this.m_ownerColl.strPk;
                    String[] strFields = str1.Split(',');
                    foreach (string fn in strFields)
                    {// Todos los campos
                        val1 = rs[fn];
                        //
                        str2 = string.Format("{0}={1}", fn, DevelopObjectValue(val1));
                        if (!CStringUtils.IsEmptyString(strKey))
                            strKey += ",";
                        strKey += str2;
                    }// Todos los campos
                    */
				} // Es múltiple, desarrollar este valor
				if (!StringUtils.IsEmptyString(strKey)) {
					// Ver si ya está en lista
					if (this.m_ownerColl.BrowseData.ObjectList.containsKey(strKey)) obj = this.m_ownerColl.BrowseData.ObjectList.get(strKey) as XoneDataObject;
				} // Ver si ya está en lista
				/*
                    Puede ser que ya este objeto ande por la lista
                    aunque con otros valores. A los efectos esto viene
                    a ser una especie de Refresh. La idea sería recargar
                    el original y devolver ese, pues puede haber referencias
                    a él en alguna otra parte. Así que primero hay que
                    buscar este ID en la lista
                */
				// M11092601: Modificaciones para mejorar el soporte de objetos volátiles.
				// Volatile debería ser
				let bNewObject = false;
				if (obj == null) {
					// No existe, así que crear nuevo
					//  Primero tiene que comprobar que haya un GUID válido
					//  o al menos una cadenilla ProgID para construir los
					//  objetos. Indicar que se trata de un objeto creado solo para cargarlo.
					obj = await this.m_ownerColl.CreateObject(false);
					if (null == obj) {
						// Error
						rs.close();
						rs = null;
						// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
						////throw new Exception("CASDataColl::LookupObject ha fallado. CreateObject ha devuelto NULL.");
						let sb = this.m_ownerColl.GetMessage(XoneMessageKeys.SYS_MSG_COLL_LOOKUPOBJFAIL_03, "{0} failed. CreateObject returned NULL.");
						sb = sb.replace("{0}", FunctionName);
						throw new XoneGenericException(-5635, sb);
					} // Error
					// M11092601: Modificaciones para mejorar el soporte de objetos volátiles.
					bNewObject = true;
				} // No existe, así que crear nuevo
				// Cargar el objeto
				// M11092601: Modificaciones para mejorar el soporte de objetos volátiles.
				// Cargar el objeto solamente si es nuevo o volátil...
				let bLoadOk = true;
				if (bNewObject || this.m_ownerColl.Options.bVolatile) bLoadOk = await obj.Load(rs);
				rs.close();
				rs = null;
				if (!bLoadOk) {
					// Error
					obj = null;
					return null;
				} // Error
				// M11092601: Modificaciones para mejorar el soporte de objetos volátiles.
				// Si el objeto es nuevo o volátil lo adicionamos a la lista...
				if (bNewObject) {
					// Nuevo. Adicionar a la lista
					//
					// Adicionar el objeto a la colección y a los posibles
					// índices
					if (!this.m_ownerColl.addItem(obj)) {
						// Error
						obj = null;
						return null;
					} // Error
				} // Nuevo. Adicionar a la lista
				//
				// Si había más de un objeto, particularizar
				// la búsqueda según los otros campos
				// Esta cosa finalmente nunca se usa para lo que
				// fue creada, así que en WInCE nadie la echará de menos
			} // Lo ha encontrado
			else {
				// Nionionio
				// F12110901: No se cierra en recordset cuando no se encuentra un objeto.
				// Cerramos
				rs.close();
				rs = null;
			} // Nionionio
			//
			// Retornar el objeto encontrado
			return obj;
		} catch (e) {
			// Si hay recordset tenemos que cerrarlo
			if (rs != null) {
				// Hay recordset
				try {
					rs.close();
				} catch (se) {
					// Ignorar esta excepción
				}
				rs = null;
			} // Hay recordset
			/*
            if (conn != null)
            {// Cerrarla
                try
                {
                    conn.close();
                }
                catch (SQLException se)
                {
                    // Ignorar esta excepción
                }
                conn = null;
            }// Cerrarla
            */
			// Lanzar la excepción
			throw e;
		}
	}

	/**
	 * Efectúa la búsqueda de un objeto en la base de datos
	 *
	 * @return					Devuelve el objeto si se encuentra en base de datos o NULL si no se encuentra.
	 * @throws Exception
	 */
	private async LookupObjectByObject(objFind: IXoneObject): Promise<XoneDataObject> {
		let strKey: string = null;
		let obj: XoneDataObject = null;
		let rs: IResultSet = null;
		let val1: Object = null;
		const FunctionName = "CXoneDataCollection::LookupObject";

		try {
			/*if (m_bDebug)
            {// Dejar traza del SQL
                str = "\r\n" + strCmd + "\r\n";
                m_owner.WriteConsoleString(str);
            }// Dejar traza del SQL
            // A12042503: Mecanismo para registrar un modo debug global en la aplicación.
            if (this.getIsDebugging() || this.getOwnerApp().isDebugMode())
                Utils.DebugLog(Utils.TAG_DATABASE_LOG,"LookupObject: "+strCmd);*/
			//////strCmd = InsertTop(strCmd, 1);
			if (
				null ==
				(rs = await this.m_ownerColl.getConnection().CreateRecordsetAsync(null, {
					count: true,
					coll: this.m_ownerColl.getName(),
					loadall: true,
					page: {},
				}))
			) {
				//  this.m_ownerColl.getName(), null, -1 new CallParameter(objFind), -1)))
				// Error
				// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
				////throw new Exception("XoneDataCollection::LookupObject ha fallado. Error ejecutando la sentencia de acceso a los datos.");
				let sb = this.m_ownerColl.GetMessage(XoneMessageKeys.SYS_MSG_COLL_LOOKUPOBJFAIL_02, "{0} failed. Error executing data access query.");
				sb = sb.replace("{0}", FunctionName);
				throw new XoneGenericException(-5634, sb);
			} // Error
			//
			// Ver si ha encontrado algo
			if (await rs.next()) {
				// Lo ha encontrado
				//
				// Comprobar la cantidad de records que se encontraron
				if (!this.m_ownerColl.strPk.contains(",")) {
					// Una sola
					strKey = "";

					// M10052401:	Modificaciones en la forma de acceso a bases de datos SQLite.
					// K11010501:	Modificaciones para la versión 1.5 de Android.
					try {
						val1 = rs.getValue(this.m_ownerColl.strPk, this.m_ownerColl.Options.nPkType);
						// Los datos de tipo VT_DECIMAL tienen que convertirse a LONG para que no haya
						// ambiguedades con las claves
						if (!this.m_ownerColl.Options.bStringPk) val1 = NumberUtils.SafeToInt(val1);
						strKey = this.m_ownerColl.BrowseData.DevelopObjectValue(val1);
					} catch (ex) {}
				} // Una sola
				else {
					// Es múltiple, desarrollar este valor
					// TODO Ver si las claves múltiples serán usadas o no... a fin de cuentas eso no lo usa nadie en otras plataformas.
					/*
                    strKey = "";
                    str1 = this.m_ownerColl.strPk;
                    String[] strFields = str1.Split(',');
                    foreach (string fn in strFields)
                    {// Todos los campos
                        val1 = rs[fn];
                        //
                        str2 = string.Format("{0}={1}", fn, DevelopObjectValue(val1));
                        if (!CStringUtils.IsEmptyString(strKey))
                            strKey += ",";
                        strKey += str2;
                    }// Todos los campos
                    */
				} // Es múltiple, desarrollar este valor
				if (!StringUtils.IsEmptyString(strKey)) {
					// Ver si ya está en lista
					if (this.m_ownerColl.BrowseData.ObjectList.containsKey(strKey)) obj = this.m_ownerColl.BrowseData.ObjectList.get(strKey) as XoneDataObject;
				} // Ver si ya está en lista
				/*
                    Puede ser que ya este objeto ande por la lista
                    aunque con otros valores. A los efectos esto viene
                    a ser una especie de Refresh. La idea sería recargar
                    el original y devolver ese, pues puede haber referencias
                    a él en alguna otra parte. Así que primero hay que
                    buscar este ID en la lista
                */
				// M11092601: Modificaciones para mejorar el soporte de objetos volátiles.
				// Volatile debería ser
				let bNewObject = false;
				if (obj == null) {
					// No existe, así que crear nuevo
					//  Primero tiene que comprobar que haya un GUID válido
					//  o al menos una cadenilla ProgID para construir los
					//  objetos. Indicar que se trata de un objeto creado solo para cargarlo.
					obj = await this.m_ownerColl.CreateObject(false);
					if (null == obj) {
						// Error
						rs.close();
						rs = null;
						// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
						////throw new Exception("CASDataColl::LookupObject ha fallado. CreateObject ha devuelto NULL.");
						let sb = this.m_ownerColl.GetMessage(XoneMessageKeys.SYS_MSG_COLL_LOOKUPOBJFAIL_03, "{0} failed. CreateObject returned NULL.");
						sb = sb.replace("{0}", FunctionName);
						throw new XoneGenericException(-5635, sb);
					} // Error
					// M11092601: Modificaciones para mejorar el soporte de objetos volátiles.
					bNewObject = true;
				} // No existe, así que crear nuevo
				// Cargar el objeto
				// M11092601: Modificaciones para mejorar el soporte de objetos volátiles.
				// Cargar el objeto solamente si es nuevo o volátil...
				let bLoadOk = true;
				if (bNewObject || this.m_ownerColl.Options.bVolatile) bLoadOk = await obj.Load(rs);
				rs.close();
				rs = null;
				if (!bLoadOk) {
					// Error
					obj = null;
					return null;
				} // Error
				// M11092601: Modificaciones para mejorar el soporte de objetos volátiles.
				// Si el objeto es nuevo o volátil lo adicionamos a la lista...
				if (bNewObject) {
					// Nuevo. Adicionar a la lista
					//
					// Adicionar el objeto a la colección y a los posibles
					// índices
					if (!this.m_ownerColl.addItem(obj)) {
						// Error
						obj = null;
						return null;
					} // Error
				} // Nuevo. Adicionar a la lista
				//
				// Si había más de un objeto, particularizar
				// la búsqueda según los otros campos
				// Esta cosa finalmente nunca se usó para lo que
				// fue creada, así que en WInCE nadie la echará de menos
			} // Lo ha encontrado
			else {
				// Nionionio
				// F12110901: No se cierra en recordset cuando no se encuentra un objeto.
				// Cerramos
				rs.close();
				rs = null;
			} // Nionionio
			//
			// Retornar el objeto encontrado
			return obj;
		} catch (e) {
			// Si hay recordset tenemos que cerrarlo
			if (rs != null) {
				// Hay recordset
				try {
					rs.close();
				} catch (se) {
					// Ignorar esta excepción
				}
				rs = null;
			} // Hay recordset
			/*
            if (conn != null)
            {// Cerrarla
                try
                {
                    conn.close();
                }
                catch (SQLException se)
                {
                    // Ignorar esta excepción
                }
                conn = null;
            }// Cerrarla
            */
			// Lanzar la excepción
			throw e;
		}
	}
}
