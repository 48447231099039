import Hashtable from "../Collections/HashMap/Hashtable";
import Vector from "../Collections/Vector";
import XoneWebCoreResulset from "../Connection/WebCore/XoneWebCoreResulset";
import XoneConnectionData from "../Connection/XoneConnectionData";
import { Exception } from "../Exceptions/Exception";
import XoneGenericException from "../Exceptions/XoneGenericException";
import { XoneMessageKeys } from "../Exceptions/XoneMessageKeys";
import IResultSet from "../Interfaces/IResultSet";
import { QueryTable } from "../Parsers/SQL/QueryTable";
import { SqlParser } from "../Parsers/SQL/SqlParser";
import Calendar from "../Utils/Calendar";
import DataUtils from "../Utils/DataUtils";
import NumberUtils from "../Utils/NumberUtils";
import ObjUtils from "../Utils/ObjUtils";
import SqlType from "../Utils/SqlType";
import StringBuilder from "../Utils/StringBuilder";
import StringUtils from "../Utils/StringUtils";
import TextUtils from "../Utils/TextUtils";
import { Utils } from "../Utils/Utils";
import { XoneDataCollection } from "./XoneDataCollection";
import { XoneDataObject } from "./XoneDataObject";
import XoneLookupObject from "./XoneLookupObject";

enum MovePtrType {
	MOVE_PTR_FIRST = 1,
	MOVE_PTR_LAST,
	MOVE_PTR_NEXT,
	MOVE_PTR_PREV,
}

// type PageCall {
//     start: number,
//     length: number
// }

// type OnlineCall {
//     count: number,
//     coll: string,
//     page: PageCall,
//     loadall: boolean
// }

export default class XoneBrowseData {
	private m_dataColl: XoneDataCollection;
	private m_nBrowseLength: number;
	private m_nCurrentIndex: number;
	private m_nCurrentRow: number;
	private m_rs: IResultSet;
	private m_bIsSpecial: boolean;
	private m_parsedAccessString: SqlParser;
	private m_connection: XoneConnectionData;
	private m_strConnectionName: string;
	private m_lstOrderedList: Array<XoneDataObject>;
	private m_lstObjectList: Hashtable<string, XoneDataObject>;
	private m_lookupObject: XoneLookupObject;
	private m_lstCopyList: Vector<XoneDataObject>;
	private m_lstObjectLru: Vector<XoneDataObject>;
	private m_nBrowseOrder: number;
	/**
	 *  Lista con los mapeos de nombres de campos para el trabajo con nombres de sistema
	 */
	private m_lstFieldMappings: Hashtable<string, string>;
	private m_pCurrentItem: any;
	private m_bDeferredLoad: any;
	private m_bSingleObject: any;
	private m_nMaxRows: number;
	private m_bHasTopRow: boolean;
	private m_pTopRowItem: any;
	private m_currentStart: number;
	private m_currentLength: number;
	private m_bIsFetchDataRunning: boolean;

	/**
	 *
	 */
	constructor(dataColl: XoneDataCollection) {
		this.m_dataColl = dataColl;
		let str = "";
		this.m_lookupObject = new XoneLookupObject(dataColl.getOwnerApp(), dataColl);
		// Crear la lista interna
		this.m_lstObjectList = new Hashtable<string, XoneDataObject>();
		this.m_lstOrderedList = new Array<XoneDataObject>();
		this.m_lstObjectLru = new Vector<XoneDataObject>();
		this.m_lstFieldMappings = new Hashtable<string, string>();
		this.m_currentStart = 0;
		this.m_currentLength = 1;
		this.m_nCurrentIndex = 0;
		this.m_bIsFetchDataRunning = false;
		if (!StringUtils.IsEmptyString((str = this.DataColl.CollPropertyValue("connection")))) {
			// Guardar el nombre
			this.m_strConnectionName = str;
			// Intenta obtener la conexión ya aquí... si no la encuentra, no pasa nada
			this.m_connection = this.DataColl.getOwnerApp().getConnection(this.m_strConnectionName);
		} // Guardar el nombre
		// F11051804: Si hay atributo userawsql en una colección, no se coge la conexión correcta.
		// Esta es por defecto...
		else this.m_connection = this.DataColl.getOwnerApp().getConnection();
	}

	private get DataColl(): XoneDataCollection {
		return this.m_dataColl;
	}

	private get Options(): any {
		return this.m_dataColl.Options;
	}

	private isEquivalent(a, b) {
		// Create arrays of property names
		var aProps = Object.getOwnPropertyNames(a);
		var bProps = Object.getOwnPropertyNames(b);

		// If number of properties is different,
		// objects are not equivalent
		if (aProps.length != bProps.length) {
			return false;
		}

		for (var i = 0; i < aProps.length; i++) {
			var propName = aProps[i];

			// If values of same property are not equal,
			// objects are not equivalent
			if (a[propName] !== b[propName]) {
				return false;
			}
		}

		// If we made it this far, objects
		// are considered equivalent
		return true;
	}

	public AddItem(Item: XoneDataObject, Index: number = -1): boolean {
		let bAdd = true;
		let str = "",
			strKey = "";
		let objLru: XoneDataObject;
		if (this.m_lstCopyList != null) {
			this.m_lstCopyList = new Vector<XoneDataObject>();
		}
		//
		// Primero ver si hay clave
		if ("NULL".equals((strKey = Item.GetObjectIdString()))) strKey = null;
		//
		// Si ahora no la tiene, jodío está el pobre
		if (!StringUtils.IsEmptyString(strKey)) {
			// Tiene clave
			// Buscar si ya este objeto está en la colección
			if (this.m_lstObjectList.containsKey(strKey)) {
				// Ya hay un objeto con esta clave
				let old = this.m_lstObjectList.get(strKey);
				if (old != null)
					if ((bAdd = !this.isEquivalent(old, Item))) {
						if (this.m_lstOrderedList.contains(old)) this.m_lstOrderedList.remove(old);
						if (this.m_lstObjectLru.contains(old)) this.m_lstObjectLru.removeElement(old);
					} else {
						return true;
					}
				// Comprobar si es este mismo
				//bAdd = false; // Es diferente
			} // Ya hay un objeto con esta clave
			if (bAdd) {
				// Hay que adicionar
				this.m_lstObjectList.put(strKey, Item);
			} // Hay que adicionar
		} // Tiene clave
		// Si la colección tiene límite, tenemos que cargarnos el objeto menos recientemente
		// accedido, de manera que el nuevo pase a ser el más recientemente usado...
		if (this.Options.nThreshold > 0) {
			// Ver si se ha sobrepasado el límite
			if (this.m_lstObjectLru.contains(Item)) this.m_lstObjectLru.removeElement(Item); // Existe en el LRU, eliminarlo de la lista
			// Ahora buscar el menos recientemente usado y sacarlo de la lista
			if (this.m_lstOrderedList.length >= this.Options.nThreshold) {
				// Buscar el menos recientemente usado
				objLru = this.m_lstObjectLru[0]; // El primero
				if (!StringUtils.IsEmptyString((str = objLru.GetObjectIdString()))) {
					// Si está en el hash, nos lo cargamos
					if (this.m_lstObjectList.containsKey(str)) this.m_lstObjectList.delete(str);
				} // Si está en el hash, nos lo cargamos
				// Ahora nos lo cargamos de la lista
				this.m_lstOrderedList.remove(0);
				// Y del LRU
				this.m_lstObjectLru.removeElement(objLru);
			} // Buscar el menos recientemente usado
		} // Ver si se ha sobrepasado el límite
		if (Index >= 0) {
			// Tiene índice
			if (this.m_lstOrderedList.length > Index) this.m_lstOrderedList.add(Index, Item);
			else this.m_lstOrderedList.push(Item);
		} // Tiene índice
		else {
			// Adicionar a la lista
			// Si no está en lista, lo adicionamos
			if (!this.m_lstOrderedList.contains(Item)) this.m_lstOrderedList.push(Item);
		} // Adicionar a la lista
		// Si hay LRU, adicionar al final
		if (this.Options.nThreshold > 0) this.m_lstObjectLru.push(Item); // Adicionar este al final
		// Si se ha adicionado, actualizar el BL solo si es especial
		if (this.m_bIsSpecial) this.m_nBrowseLength = this.m_lstOrderedList.length;
		// Completo
		return true;
	}

	/**
	 * Devuelve el índice de un objeto dentro de la colección
	 * @param Item			Objeto cuyo índice se quiere conocer.
	 * @return				Devuelve el índice del objeto o -1 si no está en la lista.
	 */
	public ObjectIndex(Item: XoneDataObject): number {
		// @ts-ignore
		if (!this.m_lstOrderedList.contains(Item) && !this.m_lstOrderedList.find(e => e.ID === Item.ID))
		return -1;
		// @ts-ignore
        const obj = (this.m_lstOrderedList.find(e => e === Item || e.ID === Item.ID));
        return this.m_lstOrderedList.indexOf(obj);
	}

	public GetObjectInt(Index: number): XoneDataObject {
		// Devolver el elemento indicado por Index
		if (this.m_lstOrderedList.length <= Index) return null;
		if (Index < 0) Index = 0;
		return this.m_lstOrderedList[Index];
	}

	public get ObjectList(): Hashtable<string, Object> {
		return this.m_lstObjectList;
	}

	/**
	 * Devuelve un objeto cuya clave se pasa como parámetro. Busca en colección primero y si no se encuentra se busca
	 * en la base de datos siguiendo el criterio de esta clave.
	 * @param Key		Clave de acceso (ID llevado a cadena o clave de texto que lo identifica)
	 * @return			Devuelve el objeto cuya clave corresponde con el parámetro indicado.
	 * @throws Exception
	 */
	public async GetObjectSring(Key: string): Promise<XoneDataObject> {
		// Buscar en la lista de objetos en memoria por la clave
		if (this.m_lstObjectList.containsKey(Key)) return this.m_lstObjectList.get(Key);
		// De lo contrario habrá que buscarlo...
		let strField = this.DataColl.getIdFieldName();
		let objVal: Object = null;
		if (this.Options.bStringPk) objVal = Key;
		else objVal = NumberUtils.SafeToInt(Key);
		if (objVal == null) return null;
		// Ahora tenemos que buscar usando esta pareja como si fuera una búsqueda normal
		return await this.GetObject(strField, objVal);
	}

	public GetLocalObject(FieldName: string | number | Object, FieldValue?: Object): XoneDataObject {
		// Buscar un objeto por campo=valor tiene su moña
		//let obj: XoneDataObject = null;
		if (typeof FieldName === "number") return this.GetObjectInt(FieldName as number);
		if (FieldValue == null) {
			const Key = FieldName as string;
			if (this.m_lstObjectList.containsKey(Key)) return this.m_lstObjectList.get(Key);
		}
		//return this.GetObjectSring(FieldName as string);

		// Primero buscar en la lista en memoria
		// F11110901: La búsqueda de objetos en cache debe hacerse en el OrderedList.
		// Busquemos en la lista ordenada porque igual es el mayor conjunto
		// Luis: Creo que debemos ser mas permisivos
		let e = this.m_lstOrderedList.entries;
		for (let i = 0; i < e.length; i++) {
			// Si alguno tiene este valor, lo devolvemos
			let v = e[i];
			// F11092604: Al buscar un objeto por campo/valor, si el campo no existe lanzar un error.
			// v!=null && v.get(FieldName)!=null &&  Por Ahora no lo he puesto porque tenemos que ver
			// y creo que es mejor lanzar una error con que el campo no esta definido en el mapping, que es la causa casi segura
			// TODO ADD TAG Luis Proteccion cuando un valor es null
			//if (v.get(FieldName).equals(FieldValue))
			if (ObjUtils.EqualObj(v.get(FieldName), FieldValue)) return v;
		} // Si alguno tiene este valor, lo devolvemos

		return null;
	}

	public async GetObject(
		FieldName: string | number | Object,
		FieldValue?: Object,
		UseFilters: boolean = true,
		SearchDb: boolean = true
	): Promise<XoneDataObject> {
		// Buscar un objeto por campo=valor tiene su moña
		let obj: XoneDataObject = null;
		if (typeof FieldName === "number") return this.GetObjectInt(FieldName as number);
		if (FieldValue == null) return await this.GetObjectSring(FieldName as string);

		// Primero buscar en la lista en memoria
		// F11110901: La búsqueda de objetos en cache debe hacerse en el OrderedList.
		// Busquemos en la lista ordenada porque igual es el mayor conjunto
		// Luis: Creo que debemos ser mas permisivos
		// let e =this.m_lstOrderedList.entries;
		// for (let i=0;i<e.length;i++)
		// {// Si alguno tiene este valor, lo devolvemos
		// 	let v =e[i];
		// 	// F11092604: Al buscar un objeto por campo/valor, si el campo no existe lanzar un error.
		// 	// v!=null && v.get(FieldName)!=null &&  Por Ahora no lo he puesto porque tenemos que ver
		// 	// y creo que es mejor lanzar una error con que el campo no esta definido en el mapping, que es la causa casi segura
		// 	// TODO ADD TAG Luis Proteccion cuando un valor es null
		//     //if (v.get(FieldName).equals(FieldValue))
		// 	if (ObjUtils.EqualObj(v.get(FieldName),FieldValue))
		//         return v;
		// }// Si alguno tiene este valor, lo devolvemos

		// M09072902:	Optimizaciones varias en las búsquedas de objetos.
		// Mover esto para aquí... si no hay que buscar en base de datos habrá que irse antes
		// Este tipo de campo es especial...
		// ADD TAG Luis si es especial no buscar en BD
		if ((FieldName as string)?.equals("MAP_$KEY") || this.m_bIsSpecial) SearchDb = false;
		// Si no hay que buscar en base de datos no lo hacemos
		if (SearchDb && obj == null) {
			// Buscar en base de datos
			// M09072902:	Optimizaciones varias en las búsquedas de objetos.
			// Mover estas cosas para dentro de esta condición porque no tienen sentido si no hay
			// que buscar en base de datos.
			// No lo tiene, seguramente habrá que buscar en base de datos
			// Comprobar si el campo es el de ID, en cuyo caso habrá que hacer algunas conversiones
			let strKeySearch = await this.GenerateKeySearch(FieldName as string, FieldValue);
			let strCmd = await this.GenerateSearchSql(strKeySearch);
			// Buscar en base de datos
			return await this.LookupObject(strCmd, strKeySearch, await this.IsSqlPrefiltered());
			//   if (
			//     null !=
			//     (obj =
			//   ) {
			//     // Lo tenemos
			//     obj.OnNormalSearh();
			//     return obj;
			//   } // Lo tenemos
		} // Buscar en base de datos
		//
		// TODO:    Ver si pasamos el mecanismo de helpers para otros tipos de búsqueda y tal...
		// Al final devolver lo que setenga
		return obj;
	}

	public async LookupObject(strCmd: string, strKeySearch: string, arg2: boolean): Promise<XoneDataObject> {
		return await this.m_lookupObject.LookupObject(strCmd, strKeySearch, arg2);
	}

	/**
	 * Efectúa una búsqueda en la base de datos dado un criterio de búsqueda tipo WHERE. Si se encuentra el objeto, se adiciona a la colección.
	 * @param SearchCriteria				Criteriors de búsqueda para armar la sentencia SQL
	 * @return								Devuelve el objeto buscado según los criterios o NULL si no se puede encontrar.
	 * @throws XoneGenericException
	 */
	public async findObject(SearchCriteria: string): Promise<XoneDataObject> {
		let strCmd = "",
			str = "",
			strTmp = "";
		let obj: XoneDataObject = null;

		try {
			// F11111103: FindObject debería limpiar el error antes de empezar a trabajar.
			//this.DataColl.getOwnerApp().ClearError();

			if (StringUtils.IsEmptyString(SearchCriteria)) return null;
			// str = await this.ApplyFilters(false);
			// if (!StringUtils.IsEmptyString(str))
			//     str = " AND (" + str + ")";
			// Preparar los criterios
			strTmp = await this.PrepareFilter(SearchCriteria);
			// str = strTmp + str;		// Los paréntesis por si las moscas
			// if (this.m_bSingleObject)
			//     strCmd = "SELECT * FROM " + this.DataColl.getAccessString() + " rrss WHERE " + str;
			// else {// Un query
			//     if (!this.DataColl.Options.bUseRaw)
			//         strCmd = "SELECT * FROM (" + this.DataColl.getAccessString() + ") rrss WHERE " + str;
			//     else
			//         strCmd = await this.EmbedFilters(str);
			// }// Un query
			obj = await this.LookupObject(strCmd, strTmp, await this.IsSqlPrefiltered());
			return obj;
		} catch (ex) {
			//TODO ADD TAG Juan Carlos. getMessage puede devolver null.
			let sDetails = ex.message;
			// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
			////throw new XoneGenericException(-1002, "CXoneDataCollection::findObject ha fallado. " + e.getMessage());
			let sMessage = this.DataColl.GetMessage(XoneMessageKeys.SYS_MSG_GENERALFAIL, "{0} failed. ");
			sMessage = sMessage.replace("{0}", "CXoneDataCollection::findObject");
			if (!StringUtils.IsEmptyString(sDetails)) {
				sMessage = sMessage.concat(sDetails);
			}
			throw new XoneGenericException(-1002, ex, sMessage);
		}
	}

	private PrepareSpecialCollection(): boolean {
		// F11090501: onprepare debe fallar si no hay nodo, porque es lo que se espera fuera.
		// Si no hay nodo, fallar...
		if (null == this.m_dataColl.getNode("onprepare")) return false; // No hay nodo
		// Ejecutar las acciones de este nodo
		return false; // TODO: ExecuteCollAction("onprepare");
	}

	private async CountAsync(data: object): Promise<number> {
		let rs = await this.DataColl.getConnection().CountAsync(null, data);
		if (rs) {
			if (await rs.next()) {
				return rs.getInt("N");
			}
		}
		return -1;
	}

	/**
	 * Inicializa un recorrido por los objetos en base de datos. Cuenta los elementos en función de lo que se indique como parámetro.
	 * @param CountRecords		TRUE para contar los elementos del recorrido
	 * @return					Devuelve TRUE si el recorrido se inicia correctamente.
	 * @throws Exception
	 */
	public async startBrowse(CountRecords: boolean = false, options?: any): Promise<XoneDataCollection> {
		this.m_bIsSpecial = this.DataColl.Options.bIsSpecial;
		let strCmd = "",
			str = "",
			str1 = "",
			strAccString = "";
		let bPrefiltered = false;

		// Amiyares 10/11/2021: simulacion de datos o carga por json
		// @ts-ignore
		const json = window.generateTestingData ? window.generateTestingData(this.DataColl) : this.DataColl.getXmlNode().getAttrValue("json");

		if (json) {
			try {
				const data = typeof json === "string" ? (() => {}).constructor(`return ${json}`)() : json;
				for (const item of data) {
					const dataObject = await this.DataColl.CreateObject();
					Object.entries(item).forEach(([key, value]) => (dataObject[key] = value));
					this.DataColl.addItem(dataObject);
				}
				this.m_nBrowseLength = data.length;
				this.m_nCurrentIndex = 0;
				// @ts-ignore
				this.m_rs = new XoneWebCoreResulset(this.DataColl.getConnection().GetCurrentConnection(), data);
				// @ts-ignore
				this.m_rs.m_data = data;
				// @ts-ignore
				this.m_rs.m_keys = Object.keys(data[0]);
				// @ts-ignore
				this.m_rs.m_maxRows = data.length;
				if (!(await this.MoveFirst())) {
					// Error
					this.m_rs.close();
					this.m_rs = null;
					return null;
				} // Error
				// M11111701: Protección de colecciones abiertas por error en scripts.
				// Si tenemos recordset, entonces tenemos que notificarlo
				this.DataColl.getOwnerApp().AddOpenColl(this.DataColl);
				return this.DataColl;
			} catch (ex) {
				console.error("Error getting json.", ex);
			}
		}

		//  Si es una colección especial, es el objeto
		//  propietario quien tiene que llenarla. Problema
		//  suyo...
		if (this.m_bIsSpecial) {
			// Preparar la colección para el recorrido
			if (!this.PrepareSpecialCollection()) {
				// No tiene nodo, prepararla en el propietario o fallar
				if (this.DataColl.getOwnerObject() != null) this.DataColl.getOwnerObject().PrepareSpecialCollection(this.DataColl);
				this.m_nBrowseLength = this.m_lstOrderedList.length;
				this.m_nCurrentIndex = 0;
				if (await this.LoadCurrentItem()) return this.DataColl;
			} // No tiene nodo, prepararla en el propietario o fallar
		} // Preparar la colección para el recorrido
		else this.EndBrowse();
		while (this.m_bIsFetchDataRunning == true) await (() => new Promise<void>((resolve) => setTimeout(() => resolve(), 100)))();
		// Ahora hay que crear un nuevo recordset e iniciar el
		// recorrido
		try {
			this.m_bIsFetchDataRunning = true;
			this.m_nCurrentRow = 0;
			this.ClearCurrentItem(true); // El actual y el anterior
			//
			// Preparar el comando
			// Como en LoadAll, puede ser que la cadena SQL no sea válida, así que
			// nos protegemos al menos de que sea una cadena vacía...
			if (StringUtils.IsEmptyString((strAccString = this.DataColl.getAccessString())) && !this.getConnection().acceptsEmptyQueries()) return null;
			// Amiyares 03/02/2022: Arreglamos esto porque no coge la sql y no resuelve las macros
			if (TextUtils.isEmpty((str = this.DataColl.getAccessString())) && !this.getConnection().acceptsEmptyQueries()) return this.DataColl; // Cadena vacía
			// Preparar el comandillo
			strCmd = "";
			// if (this.DataColl.Options.bParseSQL) {
			//     if (this.DataColl.Options.bSingleObject)
			//         strCmd = "SELECT * FROM " + strAccString + " rrss";
			//     else if (!this.DataColl.Options.bUseRaw)
			//         strCmd = "SELECT * FROM (" + strAccString + ") rrss";
			//     // Comprobar si ya trae WHERE de antes, entonces no habrá que cambiarlo por HAVING
			//     // porque estará bien, digo yo...
			//     if (this.DataColl.Options.bUseRaw && !this.DataColl.Options.bSingleObject) {// Ver si está prefiltrado
			//         bPrefiltered = await this.IsSqlPrefiltered();
			//     }// Ver si está prefiltrado
			//     // Aplica el filtro de enlace
			//     if (!this.DataColl.Options.bUseRaw)
			//         strCmd += await this.ApplyFilters(true);
			//     else
			//         strCmd = await this.EmbedFilters(this.m_parsedAccessString);
			// } else {
			//     strCmd = strAccString;
			//     bPrefiltered = false;
			// }
			// // Aplica el ordenamiento si existe
			// str1 = this.PrepareSort(this.DataColl.getSort());
			// if (!StringUtils.IsEmptyString(str1)) {// Tiene sort
			//     str = (" ORDER BY " + str1);
			//     strCmd += str;
			// }// Tiene sort
			// //
			// // Preparar las posibles macros globales
			// // F12042601: PrepareSqlString no delega en la aplicación cuando se llama a la colección.
			// strCmd = this.PrepareSqlString(strCmd, false, bPrefiltered);
			// // Comprobar si es un contents
			// if (this.DataColl.getOwnerObject() != null && strCmd.contains("##"))
			//     strCmd = this.DataColl.getOwnerObject().PrepareSqlString(strCmd);
			// //////if (strCmd.contains("##"))
			// //////	strCmd =m_owner.PrepareSqlString(strCmd);
			// // Evaluar las macros
			// strCmd = await this.DataColl.EvaluateAllMacros(strCmd, true);

			const swhere = await this.ResolveFilters(false, null);
			const sSort = this.DataColl.getSort();
			this.DataColl.ParseMacros(swhere);
			this.DataColl.ParseMacros(sSort);
			this.DataColl.ParseMacros(str);
			strCmd = await this.DataColl.EvaluateAllMacros(str, true);
			const data = {
				coll: this.DataColl.getName(),
				macros: this.DataColl.getMacros(),
				where: swhere,
				sort: sSort,
				loadall: false,
				page: {},
			};

			//  Los DBMS que usan el SQL tal cual no pueden sustituir el LIMIT usando macrosustitución
			//  porque no se puede encerrar un SELECT dentro de otro, así que habrá que ponerlo al final
			//  en esos casos...
			if (this.DataColl.Options.bDebug) {
				// Dejar traza del SQL
				str = "\r\n" + strCmd + "\r\n";
				this.DataColl.getOwnerApp().writeConsoleString(str);
			} // Dejar traza del SQL
			// Si estamos en debug, habrá que sacar alguna cosilla
			// A12042503: Mecanismo para registrar un modo debug global en la aplicación.
			if (this.DataColl.getIsDebugging() || this.DataColl.getOwnerApp().isDebugMode())
				Utils.DebugLog(Utils.TAG_DATABASE_LOG, "StartBrowse: " + strCmd);
			// Contar records de forma directa, por ahora no lo utilizo
			if (CountRecords == true)
				this.m_nBrowseLength = await this.CountAsync({
					...{ count: true, page: {} },
					...data,
				});
			else this.m_nBrowseLength = -1;
			if (
				null ==
				(this.m_rs = await this.getConnection().CreateRecordsetAsync(strCmd, {
					...{
						count: CountRecords,
						page: options != null ? options : {},
					},
					...data,
				}))
			) {
				// Error
				// Error
				// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
				////throw new XoneGenericException(-1900, "Error intentando abrir la conexión de datos de la colección '" + m_strName + "'");
				let sb = this.DataColl.GetMessage(XoneMessageKeys.SYS_MSG_COLL_COLLCONNOPENERR, "Error opening connection in collection '{0}'");
				sb = sb.replace("{0}", this.DataColl.getName());
				throw new XoneGenericException(-1900, sb);
			} // Error
			// // Obtener la cantidad de elementos
			// // cambio Sergio
			// if (CountRecords && !(this.m_rs.EOF()))
			//     this.m_nBrowseLength = await this.GetRowCount();    // Buscar la cantidad de elementos a recorrer
			// else
			//     this.m_nBrowseLength = -1;

			//
			// Supuestamente debe cargar el primer elemento de este
			// recorrido, no es necesario meter MoveFirst
			// Ahora tendrá que seguir recorriendo la colección
			// Comprobar la máscara de visibilidad que tiene que comparar con cada
			// una de las propiedades para preparar la colección de campos a leer
			if (!(await this.MoveFirst())) {
				// Error
				this.m_rs.close();
				this.m_rs = null;
				return null;
			} // Error
			// M11111701: Protección de colecciones abiertas por error en scripts.
			// Si tenemos recordset, entonces tenemos que notificarlo
			this.DataColl.getOwnerApp().AddOpenColl(this.DataColl);
			return this.DataColl;
		} catch (ex) {
			this.m_bIsFetchDataRunning = false;
			console.error(ex);
			//ex.printStackTrace();
			// M11111701: Protección de colecciones abiertas por error en scripts.
			// Intento desesperado
			this.EndBrowse();
			// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
			/////throw new XoneGenericException(-1008, "CXoneDataCollection::StartBrowse ha fallado en la colección '" + m_strName + "'. " + e.getMessage());
			let sb = this.DataColl.GetMessage(XoneMessageKeys.SYS_MSG_GENERALFAIL, "{0} failed. ");
			sb = sb.replace("{0}", "CXoneDataCollection::StartBrowse");
			//Juan Carlos: No todas las excepciones traen mensaje extra.
			let message = ex.message;
			if (message != null) {
				sb = sb.concat(message);
			}
			throw new XoneGenericException(-1008, ex, sb);
		} finally {
			this.m_bIsFetchDataRunning = false;
		}
	}

	public browseLength(): number {
		return this.m_nBrowseLength;
	}

	/**
	 * @return		Devuelve la cantidad de filas que se van a recorrer en un SB/EB
	 */
	private async GetRowCount(): Promise<number> {
		let nCount = 0;
		let strCmd = "",
			strAccString = "",
			str = "";
		let rs: IResultSet = null;
		// O10052501:	Para contar las filas se pueden usar subqueries en sqlite.
		let prefiltered = false;
		let FunctionName = "CXoneDataCollection::GetRowCount";

		try {
			// Si no hay cadena de acceso, será que va a devolver cero filas, a menos que sea
			// especial, en ese caso devuelve el Count
			strAccString = this.DataColl.getAccessString();
			if (StringUtils.IsEmptyString(strAccString)) {
				// Ver si es especial
				if (this.m_bIsSpecial) return this.getCount();
			} // Ver si es especial
			// Preparar el SQL para contar la cantidad de ocurrencias de la clave primaria
			if (this.m_bSingleObject) strCmd = "SELECT COUNT(" + this.DataColl.strPk + ") AS N FROM " + strAccString + " rrss ";
			else {
				// No es solo una tabla
				// O10052501:	Para contar las filas se pueden usar subqueries en sqlite.
				// Usaremos un subquery aunque no está marcado para este caso concreto.
				if (!this.DataColl.Options.bUseRaw || strAccString.toLowerCase().contains("group by")) {
					// Soporta subqueries
					// O10052501:	Para contar las filas se pueden usar subqueries en sqlite.
					// Al haber agrupación tendríamos que cambiar WHERE por HAVING...
					let tmpSql = strAccString + " " + StringUtils.Replace(await this.ApplyFilters(true), " WHERE ", " HAVING ");
					strCmd = "SELECT COUNT(" + this.DataColl.strPk + ") AS N FROM (" + tmpSql + ") rrss";
					prefiltered = true;
				} // Soporta subqueries
				else {
					// No soporta subqueries
					if (this.m_parsedAccessString == null) if (!(await this.ParseAccessString())) return -1;
					// Con lo que ha sacado, armar un SQL único para contar cada
					// miembro del UNION si lo hay
					let lparser = new SqlParser(this.m_parsedAccessString);
					lparser.CopyData(this.m_parsedAccessString);
					if (!lparser.getIsUnionQuery()) {
						// Uno solo
						str = "COUNT(" + this.QualifyField(this.DataColl.strPk) + ")";
						lparser.clearQueryFields();
						lparser.AddField(str, "N");
						// Adicionar los filtros
						strCmd = await this.EmbedFilters(lparser, null);
					} // Uno solo
					else {
						// Unión
						for (let i = 0; i < lparser.getUnionQueries().length; i++) {
							// Cada uno de los subqueries
							let query = lparser.getUnionQueries()[i];
							str = "COUNT(" + this.QualifyField(this.DataColl.strPk, query) + ")";
							query.clearQueryFields();
							query.AddField(str, "N");
						} // Cada uno de los subqueries
						// Regenerar
						strCmd = await this.EmbedFilters(lparser, null);
					} // Unión
				} // No soporta subqueries
			} // No es solo una tabla
			// Preparar las posibles macros globales
			// O10052501:	Para contar las filas se pueden usar subqueries en sqlite.
			// Sustituciones propias de SQLITE para mejorar la ejecución de la sentencia.
			strCmd = this.getConnection().PrepareSqlString(strCmd, false, prefiltered);
			// M11011001:	Incluir macros globales y evaluación de dichas macros para IMEI y demás.
			// Macros y demás
			strCmd = await this.DataColl.EvaluateAllMacros(strCmd, true);
			strCmd = strCmd.replace("\t", " ");
			strCmd = strCmd.replace("\r", " ");
			strCmd = strCmd.replace("\n", " ");
			if (this.DataColl.Options.bDebug) {
				// Dejar traza del SQL
				str = "\r\n" + strCmd + "\r\n";
				this.DataColl.getOwnerApp().writeConsoleString(str);
			} // Dejar traza del SQL
			// Ahora abre el recordset para contar
			// A12042503: Mecanismo para registrar un modo debug global en la aplicación.
			if (this.DataColl.getIsDebugging() || this.DataColl.getOwnerApp().isDebugMode())
				Utils.DebugLog(Utils.TAG_DATABASE_LOG, "GetRowCount: " + strCmd);
			if (
				null ==
				(rs = await this.DataColl.getConnection().CreateRecordsetAsync(strCmd, {
					count: true,
					coll: this.DataColl.getName(),
					macros: this.DataColl.getMacros(),
					loadall: false,
					page: {},
				}))
			) {
				// Error
				// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
				////throw new XoneGenericException(-2000, "XoneDataCollection::GetRowCount ha fallado. No se puede abrir la consulta para contar los registros.");
				let sb = this.DataColl.GetMessage(XoneMessageKeys.SYS_MSG_COLL_GETROWCOUNTFAIL, "{0} failed. Row count query failed.");
				sb = sb.replace("{0}", FunctionName);
				throw new XoneGenericException(-2000, sb);
			} // Error
			//
			// Recorrer el recordset abierto e ir sumando los resultados
			while (await rs.next()) {
				// Para cada registro
				nCount += DataUtils.RsReadLong(rs, "N");
				if (this.m_nMaxRows > 0)
					if (nCount > this.m_nMaxRows) {
						// Pasado el máximo
						nCount = this.m_nMaxRows;
						break;
					} // Pasado el máximo
			} // Para cada registro
			rs.close();
			rs = null;
			return nCount;
		} catch (ex) {
			if (rs != null) {
				// Cerrar
				try {
					rs.close();
				} catch (se) {
					// Ignorar esta excepción
				}
				rs = null;
			} // Cerrar
			// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
			////throw new XoneGenericException(-87878, "CXoneDataCollection::GetRowCount ha fallado. " + e.getMessage());
			let sMessage = ex.message;
			let sb = this.DataColl.GetMessage(XoneMessageKeys.SYS_MSG_GENERALFAIL, "{0} failed. ");
			sb = sb.replace("{0}", FunctionName);
			if (!TextUtils.isEmpty(sMessage)) {
				sb = sb.concat(sMessage);
			}
			throw new XoneGenericException(-87878, ex, sb);
		}
	}

	/**
	 * Mueve el recorrido hacia el primer objeto y lo carga en el CurrentItem
	 *
	 * K12112101: Cambios de interfaz en funciones de maquinaria para facilitar la gestión visual.
	 * Poner esto público.
	 *
	 * @return			Devuelve TRUE si la operación es correcta.
	 * @throws Exception
	 */
	public async MoveFirst(): Promise<boolean> {
		// Iniciar el contador de objetos.
		this.m_nBrowseOrder = 1;
		if (this.m_bIsSpecial) {
			// Especial
			this.m_nCurrentIndex = 0;
			return await this.LoadCurrentItem();
		} // Especial
		return await this.MovePtr(MovePtrType.MOVE_PTR_FIRST);
	}

	/**
	 * Mueve el recorrido hacia el siguiente objeto
	 * @return			Devuelve TRUE si la operación es correcta.
	 * @throws Exception
	 */
	public async MoveNext(): Promise<boolean> {
		this.m_nBrowseOrder++;
		if (this.m_bIsSpecial) {
			// Especial
			if (++this.m_nCurrentIndex >= this.m_nBrowseLength) this.m_nCurrentIndex = -1;
			return await this.LoadCurrentItem();
		} // Especial

		return await this.MovePtr(MovePtrType.MOVE_PTR_NEXT);
	}

	/**
	 * Mueve el recorrido al último objeto
	 * @return			Devuelve TRUE si la operación es correcta.
	 * @throws Exception
	 */
	public async MoveLast(): Promise<boolean> {
		this.m_nBrowseOrder = -1;
		if (this.m_bIsSpecial) {
			// Especial
			this.m_nCurrentIndex = this.m_nBrowseLength > 0 ? this.m_nBrowseLength - 1 : 0;
			return await this.LoadCurrentItem();
		} // Especial

		return await this.MovePtr(MovePtrType.MOVE_PTR_LAST);
	}

	/**
	 * Mueve el recorrido al objeto anterior
	 * @return			Devuelve TRUE si la operación es correcta.
	 * @throws Exception
	 */
	public async MovePrevious(): Promise<boolean> {
		if (--this.m_nBrowseOrder < 1) this.m_nBrowseOrder = 1;
		if (this.m_bIsSpecial) {
			// Especial
			this.m_nCurrentIndex -= this.m_nCurrentIndex > 0 ? 1 : 0;
			return await this.LoadCurrentItem();
		} // Especial

		return await this.MovePtr(MovePtrType.MOVE_PTR_PREV);
	}

	/**
	 * Interna para mover el puntero de la base de datos o fuente externa en función del tipo de colección y demás.
	 * @param Type		Tipo de movimiento que se quiere efectuar (dirección de movimiento, etc.)
	 * @return			Devuelve TRUE si la operación es correcta.
	 */
	private async MovePtr(Type: number): Promise<boolean> {
		//////boolean bFrst = false;
		let bClear = false;
		let bResult = false;

		try {
			if (this.m_rs != null) {
				// Hay rs
				switch (
					Type // Qué tipo de movimiento
				) {
					case MovePtrType.MOVE_PTR_FIRST:
						await this.m_rs.next(); // Por ahora solamente leemos el primero
						//////m_bFrst = false;
						this.m_nCurrentRow = 0;
						if (this.m_bHasTopRow && this.m_pTopRowItem != null) this.m_pTopRowItem = null; // El que sea se va del aire
						break;
					case MovePtrType.MOVE_PTR_LAST:
						// m_rs ->MoveLast ();
						if (this.m_nMaxRows > 0) this.m_nCurrentRow = this.m_nMaxRows - 1; // Moverse hasta la que es
						break;
					case MovePtrType.MOVE_PTR_NEXT:
						let bHasNext = false;
						if (this.m_nMaxRows > 0) {
							// Ver si se mueve o no
							if (this.m_nCurrentRow < this.m_nMaxRows) {
								// Puede
								this.m_nCurrentRow++;
								bHasNext = await this.m_rs.next();
							} // Puede
						} // Ver si se mueve o no
						else bHasNext = await this.m_rs.next();
						// Si no hay más elementos, entonces cerramos
						if (!bHasNext) {
							// Cerrar
							this.m_rs.close();
							this.m_rs = null;
							// M11111701: Protección de colecciones abiertas por error en scripts.
							// Fundir...
							this.DataColl.getOwnerApp().RemoveOpenColl(this.DataColl);
						} // Cerrar
						break;
					case MovePtrType.MOVE_PTR_PREV:
						// TODO Ver si esto se puede hacer
						//m_rs ->MovePrevious ();
						if (--this.m_nCurrentRow < 0) this.m_nCurrentRow = 0;
						break;
				} // Qué tipo de movimiento
				bResult = await this.LoadCurrentItem();
				//////m_bFrst = bFrst;
			} // Hay rs
			else bClear = true;
			//
			// Si tiene que cargarse el elemento actual hacerlo sin miramientos
			if (bClear) this.ClearCurrentItem(true);

			return bResult;
		} catch (ex) {
			// M11111701: Protección de colecciones abiertas por error en scripts.
			// Intentar dejar esto de manera más o menos decente...
			this.EndBrowse();
			// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
			////throw new XoneGenericException(-1010, "CXoneDataCollection::MovePtr ha fallado. " + ex.getMessage());
			let str = this.m_dataColl.GetMessage(XoneMessageKeys.SYS_MSG_GENERALFAIL, "{0} failed. ");
			str = str.replace("{0}", "CXoneDataCollection::MovePtr");
			//TODO ADD TAG Juan Carlos: Evitar NullPointerException
			let sMessage = ex.message;
			if (TextUtils.isEmpty(sMessage)) {
				console.error(ex);
			} else {
				str = str.concat(sMessage);
			}
			throw new XoneGenericException(-1010, ex, str);
		}
	}

	/**
	 *  Cierra el recorrido actualmente activo.
	 */
	public EndBrowse(): boolean {
		try {
			/*
			 * TODO ADD TAG
			 * Cancelar todos los procesos que haya. La idea es que cuando esto retorne ya se pueda
			 * usar todo lo demás.
			 * TODO 28/07/2016 Juan Carlos
			 * Si no hay conexion se tiene que explotar con un mensaje claro. Por ejemplo la
			 * coleccion puede tener atributo connection pero el nodo puede no existir y
			 * getCollection() devuelve null.
			 */
			if (this.getConnection() == null) {
				let sb = this.DataColl.GetMessage(XoneMessageKeys.SYS_MSG_GENERALFAIL, "{0} failed. ");
				sb = sb.replace("{0}", "CXoneDataCollection::GetConnection");
				sb = sb.concat("Cannot find connection " + this.getConnectionName());
				throw new XoneGenericException(-1900, sb);
			}
			this.getConnection().cancelProcesses(0);
			//  Si es una colección especial, simplemente limpiar
			//  la colección de elementos
			if (this.m_bIsSpecial) {
				// Limpiar la colección
				this.DataColl.clear();
				return true;
			} // Limpiar la colección
			// Aquí continúa el proceso si es una colección normal
			this.ClearCurrentItem(true);
			if (this.m_rs != null) {
				// Hay un recordset
				this.m_rs.close();
				this.m_rs = null;
			} // Hay un recordset
			// M11111701: Protección de colecciones abiertas por error en scripts.
			// Terminamos esto
			this.DataColl.getOwnerApp().RemoveOpenColl(this.DataColl);
			return true;
		} catch (e) {
			// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
			////throw new XoneGenericException(-1009, "CXoneDataCollection::EndBrowse ha fallado. " + e.getMessage());
			let sb = this.DataColl.GetMessage(XoneMessageKeys.SYS_MSG_GENERALFAIL, "{0} failed. ");
			sb = sb.replace("{0}", "CXoneDataCollection::EndBrowse");
			sb = sb.concat(e.getMessage());
			//TODO 28/07/2016 Juan Carlos. Respetar la traza del stack original
			throw new XoneGenericException(-1009, e, sb);
		}
	}
	getConnectionName() {
		return this.m_strConnectionName;
	}

	getConnection(): XoneConnectionData {
		if (this.m_connection == null) this.m_connection = this.DataColl.getOwnerApp().getConnection(this.m_strConnectionName);
		// Devolver lo que sea
		return this.m_connection;
	}

	//   loadAll(): boolean {
	//     let rs: IResultSet = null;
	//     let strCmd: string, str: string, str1: string;
	//     let obj: XoneDataObject = null;
	//     let nOrder: number = 1;
	//     let FunctionName = "XoneDataCollection::LoadAll";
	//     let bPrefiltered = false;

	//     if (this.DataColl.isLock() === true) return true;
	//         //
	//         // Cuando se hace LoadAll a una colección especial, ver si tiene <onprepare>
	//         // para llenarse. Ojo que el chequeo de bloqueo ahora ocurre ANTES
	//         // de este código...
	//         if (this.m_bIsSpecial)
	//         {// Colecciones especiales, ePrepareSpecialCollectionsto no hace nada
	//             const b = this.PrepareSpecialCollection();
	//             this.DataColl.setFull(true);
	//             return b;
	//         }// Colecciones especiales, esto no hace nada
	//     // Cargar los datos
	//     try {
	//         this.DataColl.setFull(false);
	//             this.DataColl.clear();
	//             if (this.DataColl.Options.bCheckOwner)
	//             {// Hay que chequear el propietario
	//                 if (this.DataColl.getOwnerObject() != null)
	//                 {// Es derivado
	//                     if (this.DataColl.getOwnerObject().GetObjectIdString(true).equals("NULL"))
	//                         return true;   // No se ha grabao el puro
	//                 }// Es derivado
	//             }// Hay que chequear el propietario
	//             //
	//             // Continuar
	//             if (TextUtils.isEmpty(str = this.DataColl.getAccessString()) && !this.getConnection().acceptsEmptyQueries())
	//                 return true;    // Cadena vacía
	//             strCmd = "";
	//             if (this.Options.bParseSQL) {
	//                 if (this.Options.bSingleObject)
	//                     strCmd = "SELECT * FROM " + str + " rrss";
	//                 else
	//                 {// Query
	//                     if (!this.Options.bUseRaw)
	//                         strCmd = "SELECT * FROM (" + str + ") rrss";
	//                 }// Query
	//                 // Comprobar si ya trae WHERE de antes, entonces no habrá que cambiarlo por HAVING
	//                 // porque estará bien, digo yo...
	//                 if (this.Options.bUseRaw && !this.Options.bSingleObject) {// Ver si está prefiltrado
	//                     bPrefiltered = this.IsSqlPrefiltered();
	//                 }// Ver si está prefiltrado
	//                 // Si hay filtro de enlace lo aplica
	//                 if (!this.Options.bUseRaw)
	//                     strCmd += this.ApplyFilters(true);
	//                 else
	//                     strCmd = this.EmbedFilters(null);
	//             } else {
	//                 strCmd  = str;
	//                 bPrefiltered=false;
	//             }
	//             // Aplica el orden si existe
	//             if (!StringUtils.IsEmptyString(str1 = this.PrepareSort(this.DataColl.getSort())))
	//             {// Tiene sort
	//                 str = (" ORDER BY " + str1);
	//                 strCmd += str;
	//             }// Tiene sort

	//             // Cambiando que primero lo pase por la coleccion
	//             strCmd = this.PrepareSqlString(strCmd,false,bPrefiltered); //getConnection().PrepareSqlString(strCmd,false,bPrefiltered);
	//             // Comprobar si es un contents
	//             if (this.DataColl.getOwnerObject() != null)
	//                 strCmd = this.PrepareSqlString(strCmd);
	//             // M11011001:	Incluir macros globales y evaluación de dichas macros para IMEI y demás.
	//             // Sustituir macros (no sé por qué no se hacía)
	//             // Evaluar las macros (globales incluidas)
	//             strCmd = this.DataColl.EvaluateAllMacros(strCmd, true);
	//             if (this.Options.bDebug)
	//             {// Dejar traza del SQL
	//                 str = "\r\n" + strCmd + "\r\n";
	//                 this.DataColl.getOwnerApp().writeConsoleString(str);
	//             }// Dejar traza del SQL
	//             // A12042503: Mecanismo para registrar un modo debug global en la aplicación.
	//             if (this.DataColl.getIsDebugging() || this.DataColl.getOwnerApp().isDebugMode())
	//                 Utils.DebugLog(Utils.TAG_DATABASE_LOG,"LoadAll: "+strCmd);
	//             // TODO ADD TAG
	//             // Si hay procesos pendientes, cancelamos lo que sea que haya
	//             this.getConnection().cancelProcesses(0);
	//             // El recordset tiene que apuntar a la conexión correcta
	//             if (null == (rs = this.getConnection().CreateRecordset(strCmd)))
	//             {// Error
	//                 // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
	//                 ////throw new XoneGenericException(-2999, "CXoneDataCollection::LoadAll ha fallado. No se puede abrir la fuente de datos.");
	//                 let sb = this.DataColl.GetMessage(XoneMessageKeys.SYS_MSG_DATASRCFAIL, "{0} failed. Cannot open data source.");
	//                 sb =sb.replace("{0}", FunctionName);
	//                 throw new XoneGenericException(-2999, sb);
	//             }// Error
	//             // Ahora sí está en condiciones de recorrer el lazo
	//             // Simular el orden en el LoadAll
	//             for (; rs.next(); nOrder++)
	//             {// Todos los records
	//                 //  Crear el objeto es toda una yerba en condiciones normales
	//                 //  pero asumiremos que si no hay un GUID válido aquí sea
	//                 //  porque se va a usar el clásico. Al crear el objeto indicar que es solo para cargar...
	//                 if (null == (obj = this.DataColl.CreateObject(false)))
	//                 {// No se puede crear el objeto
	//                     // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
	//                     ////throw new XoneGenericException(-1005, "CXoneDataCollection::LoadAll no puede crear un nuevo objeto. CreateObject ha falldo.");
	//                     let sb = this.DataColl.GetMessage(XoneMessageKeys.SYS_MSG_COLL_LOADALLFAIL_02, "{0} cannot create a new object. CreateObject failed.");
	//                     sb =sb.replace("{0}", FunctionName);
	//                     throw new XoneGenericException(-1005, sb);
	//                 }// No se puede crear el objeto
	//                 // ADD TAG Cambiar de posicion el MAP_SYS_ORDER para que en el load ya este disponible en que posicion va a estar
	//                 obj.SetPropertyValue("MAP_SYS_ORDER", nOrder-1);
	//                 // Cargar el objeto
	//                 if (!obj.Load(rs))
	//                 {// Error
	//                     // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
	//                     ////throw new XoneGenericException(-10051, "CXoneDataCollection::LoadAll ha fallado. Ha ocurrido un fallo llamando a MainObject::Load.");
	//                     let sb = this.DataColl.GetMessage(XoneMessageKeys.SYS_MSG_COLL_LOADALLFAIL_03, "{0} failed. Call to XoneDataObject::Load failed.");
	//                     sb =sb.replace("{0}", FunctionName);
	//                     throw new XoneGenericException(-10051, sb);
	//                 }// Error
	//                 obj.GetObjectIdString();
	//                 this.AddItem(obj);

	//             }// Todos los records
	//             //
	//             // Cerrar y liberar todo
	//             rs.close();
	//             rs = null;
	//             this.DataColl.setFull(true);
	//       return true;
	//     } catch (e) {
	//       if (rs != null)
	//       {// Cerrar
	//           try
	//           {
	//               rs.close();
	//           }
	//           catch (se)
	//           {
	//           }
	//           rs = null;
	//       }// Cerrar
	//       throw e;
	//     }
	//     return true;
	//   }

	public async loadAllAsyncWithParser(CountRecords: boolean = false, options?: any): Promise<XoneDataCollection> {
		let rs: IResultSet = null;
		let strCmd: string, str: string, str1: string;
		let obj: XoneDataObject = null;
		let nOrder: number = 1;
		let FunctionName = "XoneDataCollection::LoadAll";
		let bPrefiltered = false;

		if (this.DataColl.isLock() === true) return this.DataColl;

		//
		// Cuando se hace LoadAll a una colección especial, ver si tiene <onprepare>
		// para llenarse. Ojo que el chequeo de bloqueo ahora ocurre ANTES
		// de este código...
		if (this.m_bIsSpecial) {
			// Colecciones especiales, ePrepareSpecialCollectionsto no hace nada
			const b = this.PrepareSpecialCollection();
			this.DataColl.setFull(true);
			return this.DataColl;
		} // Colecciones especiales, esto no hace nada
		while (this.m_bIsFetchDataRunning == true) await (() => new Promise<void>((resolve) => setTimeout(() => resolve(), 100)))();
		// Cargar los datos
		try {
			this.m_bIsFetchDataRunning = true;
			this.DataColl.setFull(false);
			this.Clear();
			if (this.Options.bCheckOwner) {
				// Hay que chequear el propietario
				if (this.DataColl.getOwnerObject() != null) {
					// Es derivado
					if (this.DataColl.getOwnerObject().GetObjectIdString(true).equals("NULL")) return this.DataColl; // No se ha grabao el puro
				} // Es derivado
			} // Hay que chequear el propietario
			//
			// Continuar
			if (TextUtils.isEmpty((str = this.DataColl.getAccessString())) && !this.getConnection().acceptsEmptyQueries()) return this.DataColl; // Cadena vacía
			strCmd = "";
			if (this.Options.bParseSQL) {
				if (this.Options.bSingleObject) strCmd = "SELECT * FROM " + str + " rrss";
				else {
					// Query
					if (!this.Options.bUseRaw) strCmd = "SELECT * FROM (" + str + ") rrss";
				} // Query
				// Comprobar si ya trae WHERE de antes, entonces no habrá que cambiarlo por HAVING
				// porque estará bien, digo yo...
				if (this.Options.bUseRaw && !this.Options.bSingleObject) {
					// Ver si está prefiltrado
					bPrefiltered = await this.IsSqlPrefiltered();
				} // Ver si está prefiltrado
				// Si hay filtro de enlace lo aplica
				if (!this.Options.bUseRaw) strCmd += await this.ApplyFilters(true);
				else strCmd = await this.EmbedFilters(null);
			} else {
				strCmd = str;
				bPrefiltered = false;
			}
			// Aplica el orden si existe
			if (!StringUtils.IsEmptyString((str1 = this.PrepareSort(this.DataColl.getSort())))) {
				// Tiene sort
				str = " ORDER BY " + str1;
				strCmd += str;
			} // Tiene sort

			// Cambiando que primero lo pase por la coleccion
			strCmd = this.PrepareSqlString(strCmd, false, bPrefiltered); //getConnection().PrepareSqlString(strCmd,false,bPrefiltered);
			// Comprobar si es un contents
			if (this.DataColl.getOwnerObject() != null) strCmd = this.DataColl.getOwnerObject().PrepareSqlString(strCmd);
			// M11011001:	Incluir macros globales y evaluación de dichas macros para IMEI y demás.
			// Sustituir macros (no sé por qué no se hacía)
			// Evaluar las macros (globales incluidas)
			strCmd = await this.DataColl.EvaluateAllMacros(strCmd, true);
			if (this.Options.bDebug) {
				// Dejar traza del SQL
				str = "\r\n" + strCmd + "\r\n";
				this.DataColl.getOwnerApp().writeConsoleString(str);
			} // Dejar traza del SQL
			// A12042503: Mecanismo para registrar un modo debug global en la aplicación.
			if (this.DataColl.getIsDebugging() || this.DataColl.getOwnerApp().isDebugMode()) Utils.DebugLog(Utils.TAG_DATABASE_LOG, "LoadAll: " + strCmd);
			// TODO ADD TAG
			// Si hay procesos pendientes, cancelamos lo que sea que haya
			this.getConnection().cancelProcesses(0);
			if (CountRecords == true) this.m_nBrowseLength = await this.CountAsync(null);
			else this.m_nBrowseLength = -1;
			// El recordset tiene que apuntar a la conexión correcta
			if (
				null ==
				(rs = await this.getConnection().CreateRecordsetAsync(strCmd, {
					count: false,
					coll: this.DataColl.getName(),
					macros: this.DataColl.getMacros(),
					loadall: false,
					page: options != null ? options : { start: 0, length: 1000 },
				}))
			) {
				// Error
				// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
				////throw new XoneGenericException(-2999, "CXoneDataCollection::LoadAll ha fallado. No se puede abrir la fuente de datos.");
				let sb = this.DataColl.GetMessage(XoneMessageKeys.SYS_MSG_DATASRCFAIL, "{0} failed. Cannot open data source.");
				sb = sb.replace("{0}", FunctionName);
				throw new XoneGenericException(-2999, sb);
			} // Error
			// Obtener la cantidad de elementos
			// cambio Sergio
			// if (CountRecords && !(this.m_rs.EOF()))
			//     this.m_nBrowseLength = await this.GetRowCount();    // Buscar la cantidad de elementos a recorrer
			// else
			//     this.m_nBrowseLength = -1;
			// Ahora sí está en condiciones de recorrer el lazo
			// Simular el orden en el LoadAll
			for (; await rs.next(); nOrder++) {
				// Todos los records
				//  Crear el objeto es toda una yerba en condiciones normales
				//  pero asumiremos que si no hay un GUID válido aquí sea
				//  porque se va a usar el clásico. Al crear el objeto indicar que es solo para cargar...
				obj = await this.DataColl.CreateObject(false);
				if (null == obj) {
					// No se puede crear el objeto
					// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
					////throw new XoneGenericException(-1005, "CXoneDataCollection::LoadAll no puede crear un nuevo objeto. CreateObject ha falldo.");
					let sb = this.DataColl.GetMessage(XoneMessageKeys.SYS_MSG_COLL_LOADALLFAIL_02, "{0} cannot create a new object. CreateObject failed.");
					sb = sb.replace("{0}", FunctionName);
					throw new XoneGenericException(-1005, sb);
				} // No se puede crear el objeto
				// ADD TAG Cambiar de posicion el MAP_SYS_ORDER para que en el load ya este disponible en que posicion va a estar
				obj.SetPropertyValue("MAP_SYS_ORDER", nOrder - 1);
				// Cargar el objeto
				if (!obj.Load(rs)) {
					// Error
					// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
					////throw new XoneGenericException(-10051, "CXoneDataCollection::LoadAll ha fallado. Ha ocurrido un fallo llamando a MainObject::Load.");
					let sb = this.DataColl.GetMessage(XoneMessageKeys.SYS_MSG_COLL_LOADALLFAIL_03, "{0} failed. Call to XoneDataObject::Load failed.");
					sb = sb.replace("{0}", FunctionName);
					throw new XoneGenericException(-10051, sb);
				} // Error
				obj.GetObjectIdString();
				this.AddItem(obj);
			} // Todos los records
			//
			// Cerrar y liberar todo
			rs.close();
			rs = null;
			this.DataColl.setFull(true);
			return this.DataColl;
		} catch (e) {
			this.m_bIsFetchDataRunning = true;
			if (rs != null) {
				// Cerrar
				try {
					rs.close();
				} catch (se) {}
				rs = null;
			} // Cerrar
			throw e;
		} finally {
			this.m_bIsFetchDataRunning = false;
		}
	}

	public async loadAllAsync(CountRecords: boolean = false, options?: any): Promise<XoneDataCollection> {
		let rs: IResultSet = null;
		let strCmd: string, str: string, str1: string;
		let obj: XoneDataObject = null;
		let nOrder: number = 1;
		let FunctionName = "XoneDataCollection::LoadAll";
		let bPrefiltered = false;

		if (this.DataColl.isLock() === true) return this.DataColl;

		// Amiyares: 20/10/2021, poder cargar datos desde atributo json en lugar de hacer siempre llamadas sql
		// TODO: Modificado el 8/11/2021 para simular datos, estudiar posible refactorización
		/* @ts-ignore */
		const json = window.generateTestingData ? window.generateTestingData(this.DataColl) : this.DataColl.getXmlNode().getAttrValue("json");

		//
		// Cuando se hace LoadAll a una colección especial, ver si tiene <onprepare>
		// para llenarse. Ojo que el chequeo de bloqueo ahora ocurre ANTES
		// de este código...
		if (this.m_bIsSpecial || json) {
			// Colecciones especiales, ePrepareSpecialCollectionsto no hace nada
			const b = this.PrepareSpecialCollection();

			// Amiyares: 20/10/2021
			if (json) {
				try {
					const data = typeof json === "string" ? (() => {}).constructor(`return ${json}`)() : json;
					for (const item of data) {
						const dataObject = await this.DataColl.CreateObject();
						Object.entries(item).forEach(([key, value]) => (dataObject[key] = value));
						this.DataColl.addItem(dataObject);
					}
				} catch (ex) {
					console.error("Error getting json.", ex);
				}
			}

			this.DataColl.setFull(true);
			return this.DataColl;
		} // Colecciones especiales, esto no hace nada
		while (this.m_bIsFetchDataRunning == true) await (() => new Promise<void>((resolve) => setTimeout(() => resolve(), 100)))();
		// Cargar los datos
		try {
			this.m_bIsFetchDataRunning = true;
			this.DataColl.setFull(false);
			this.Clear();
			if (this.Options.bCheckOwner) {
				// Hay que chequear el propietario
				if (this.DataColl.getOwnerObject() != null) {
					// Es derivado
					if (this.DataColl.getOwnerObject().GetObjectIdString(true).equals("NULL")) return this.DataColl; // No se ha grabao el puro
				} // Es derivado
			} // Hay que chequear el propietario
			//
			// Continuar
			if (TextUtils.isEmpty((str = this.DataColl.getAccessString())) && !this.getConnection().acceptsEmptyQueries()) return this.DataColl; // Cadena vacía
			strCmd = "";

			// if (this.Options.bParseSQL) {
			//     if (this.Options.bSingleObject)
			//         strCmd = "SELECT * FROM " + str + " rrss";
			//     else {// Query
			//         if (!this.Options.bUseRaw)
			//             strCmd = "SELECT * FROM (" + str + ") rrss";
			//     }// Query
			//     // Comprobar si ya trae WHERE de antes, entonces no habrá que cambiarlo por HAVING
			//     // porque estará bien, digo yo...
			//     if (this.Options.bUseRaw && !this.Options.bSingleObject) {// Ver si está prefiltrado
			//         bPrefiltered = await this.IsSqlPrefiltered();
			//     }// Ver si está prefiltrado
			//     // Si hay filtro de enlace lo aplica
			//     if (!this.Options.bUseRaw)
			//         strCmd += await this.ApplyFilters(true);
			//     else
			//         strCmd = await this.EmbedFilters(null);
			// } else {
			//     strCmd = str;
			//     bPrefiltered = false;
			// }
			// Aplica el orden si existe
			 if (!StringUtils.IsEmptyString(str1 = this.PrepareSort(this.DataColl.getSort()))) {// Tiene sort
			     str = (" ORDER BY " + str1);
			     strCmd += str;
			 }// Tiene sort

			// // Cambiando que primero lo pase por la coleccion
			// strCmd = this.PrepareSqlString(strCmd, false, bPrefiltered); //getConnection().PrepareSqlString(strCmd,false,bPrefiltered);
			// // Comprobar si es un contents
			// if (this.DataColl.getOwnerObject() != null)
			//     strCmd = this.DataColl.getOwnerObject().PrepareSqlString(strCmd);
			// M11011001:	Incluir macros globales y evaluación de dichas macros para IMEI y demás.
			// Sustituir macros (no sé por qué no se hacía)
			// Evaluar las macros (globales incluidas)
			const swhere = await this.ResolveFilters(false, null);
			const sSort = this.DataColl.getSort();
			this.DataColl.ParseMacros(swhere);
			this.DataColl.ParseMacros(sSort);
			this.DataColl.ParseMacros(str);
			strCmd = await this.DataColl.EvaluateAllMacros(str, true);
			const data = {
				coll: this.DataColl.getName(),
				macros: this.DataColl.getMacros(),
				where: swhere,
				sort: sSort,
				loadall: false,
				page: {},
			};
			if (this.Options.bDebug) {
				// Dejar traza del SQL
				this.DataColl.getOwnerApp().writeConsoleString(data);
			} // Dejar traza del SQL
			// A12042503: Mecanismo para registrar un modo debug global en la aplicación.
			if (this.DataColl.getIsDebugging() || this.DataColl.getOwnerApp().isDebugMode()) Utils.DebugLog(Utils.TAG_DATABASE_LOG, "LoadAll: " + data);
			// TODO ADD TAG
			// Si hay procesos pendientes, cancelamos lo que sea que haya
			this.getConnection().cancelProcesses(0);

			if (CountRecords == true) this.m_nBrowseLength = await this.CountAsync({ ...{ count: true, page: {} }, ...data });
			else this.m_nBrowseLength = -1;
			// El recordset tiene que apuntar a la conexión correcta
			// Amiyares 01/12/2021: El orden de asignación de data estaba al revés y machacaba la paginación
			if (
				null ==
				(rs = await this.getConnection().CreateRecordsetAsync(strCmd, Object.assign(data || {}, { count: false, page: { start: options?.start || 0, length: options?.length || 1000 } })))
			) {
				// Error
				// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
				////throw new XoneGenericException(-2999, "CXoneDataCollection::LoadAll ha fallado. No se puede abrir la fuente de datos.");
				let sb = this.DataColl.GetMessage(XoneMessageKeys.SYS_MSG_DATASRCFAIL, "{0} failed. Cannot open data source.");
				sb = sb.replace("{0}", FunctionName);
				throw new XoneGenericException(-2999, sb);
			} // Error
			// Obtener la cantidad de elementos
			// cambio Sergio
			// if (CountRecords && !(this.m_rs.EOF()))
			//     this.m_nBrowseLength = await this.GetRowCount();    // Buscar la cantidad de elementos a recorrer
			// else
			//     this.m_nBrowseLength = -1;
			// Ahora sí está en condiciones de recorrer el lazo
			// Simular el orden en el LoadAll
			for (; await rs.next(); nOrder++) {
				// Todos los records
				//  Crear el objeto es toda una yerba en condiciones normales
				//  pero asumiremos que si no hay un GUID válido aquí sea
				//  porque se va a usar el clásico. Al crear el objeto indicar que es solo para cargar...
				obj = await this.DataColl.CreateObject(false);
				if (null == obj) {
					// No se puede crear el objeto
					// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
					////throw new XoneGenericException(-1005, "CXoneDataCollection::LoadAll no puede crear un nuevo objeto. CreateObject ha falldo.");
					let sb = this.DataColl.GetMessage(XoneMessageKeys.SYS_MSG_COLL_LOADALLFAIL_02, "{0} cannot create a new object. CreateObject failed.");
					sb = sb.replace("{0}", FunctionName);
					throw new XoneGenericException(-1005, sb);
				} // No se puede crear el objeto
				// ADD TAG Cambiar de posicion el MAP_SYS_ORDER para que en el load ya este disponible en que posicion va a estar
				obj.SetPropertyValue("MAP_SYS_ORDER", nOrder - 1);
				// Cargar el objeto
				if (!obj.Load(rs)) {
					// Error
					// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
					////throw new XoneGenericException(-10051, "CXoneDataCollection::LoadAll ha fallado. Ha ocurrido un fallo llamando a MainObject::Load.");
					let sb = this.DataColl.GetMessage(XoneMessageKeys.SYS_MSG_COLL_LOADALLFAIL_03, "{0} failed. Call to XoneDataObject::Load failed.");
					sb = sb.replace("{0}", FunctionName);
					throw new XoneGenericException(-10051, sb);
				} // Error
				obj.GetObjectIdString();
				this.AddItem(obj);
			} // Todos los records
			//
			// Cerrar y liberar todo
			rs.close();
			rs = null;
			this.DataColl.setFull(true);
			return this.DataColl;
		} catch (e) {
			this.m_bIsFetchDataRunning = true;
			if (rs != null) {
				// Cerrar
				try {
					rs.close();
				} catch (se) {}
				rs = null;
			} // Cerrar
			throw e;
		} finally {
			this.m_bIsFetchDataRunning = false;
		}
	}

	// amiyares 10/06/2021 -> fallaba parseando un formato de fecha
	//PrepareSort(Sort: string): string {
	//	return Sort;
	//}

	PrepareSort(Sort: string): string {
	    let i = 0, l = 0, k = 0;
	    let str = "", str1 = "", strTmp = "", strField = "", strOut = "";
	    let bFieldComplete = false, bIsField = false;

	    if (StringUtils.IsEmptyString(Sort))
	        return Sort;
	    let strIn = Sort.trim();
	    if (StringUtils.IsEmptyString(strIn))
	        return strIn;
	    for (i = 0, l = strIn.length; i < l; i++) {// Buscar cada caracter
	        // F09042201:	Substring no funciona igual en java que en .NET Arreglar Blackberry
	        str = strIn.substring(i, i + 1);
	        if (str.equals("%") || str.equals("$") || str.equals("#")) {// Buscar la coma o el final y quitarlo
	            k = strIn.indexOf(",", i);
	            if (k == -1) {// En dependencia de si va a la mitad o no
	                if (i > 0)
	                    // F09042201:	Substring no funciona igual en java que en .NET Arreglar Blackberry
	                    return strIn.substring(i - 1, i).equals(",") ? strIn.substring(0, i - 1) : strIn.substring(0, i);
	                else
	                    return "";
	            }// En dependencia de si va a la mitad o no
	            if (i > 0)
	                // F09042201:	Substring no funciona igual en java que en .NET Arreglar Blackberry
	                str = strIn.substring(i - 1, i).equals(",") ? strIn.substring(0, i - 1) : strIn.substring(0, i);
	            else
	                str = "";
	            // F09042201:	Substring no funciona igual en java que en .NET Arreglar Blackberry
	            str1 = strIn.substring(k + 1, strIn.length - k);
	            strIn = str + str1;
	            l = strIn.length;
	            i = -1;
	        }// Buscar la coma o el final y quitarlo
	    }// Buscar cada caracter
	    strIn = strIn.trim();
	    if (StringUtils.IsEmptyString(strIn))
	        return strIn;
	    if (this.Options.bUseRaw && !this.IsUnionQuery()) {// Usa el SQL sin subqueries
	        strOut = strField = "";
	        for (i = 0, l = strIn.length, strTmp = "", bFieldComplete = false; i < l; i++) {// Cada caracter
	            let c: string;
	            switch (c = strIn.charAt(i)) {
	                case ' ': // Espacios
	                case ',':
	                    if (bIsField) {// Se acabó el campo
	                        // F09042201:	Substring no funciona igual en java que en .NET Arreglar Blackberry
	                        for (strTmp = strIn.substring(i, i + 1); i < l && strIn.substring(i + 1, i + 2).equals(" "); strTmp += strIn.substring(i, i + 1), i++);
	                        bFieldComplete = true;
	                        bIsField = false;
	                    }// Se acabó el campo
	                    else
	                        strOut += c;
	                    break;
	                default:
	                    if (bIsField)
	                        strField += c;
	                    else {// No contar este
	                        bIsField = true;
	                        i--;
	                    }// No contar este
	                    break;
	            }
	            // Si ha terminado un campo procesarlo
	            if (bFieldComplete) {// Comprobar si es un operador
	                str = strField.toLowerCase();
	                if (!(str.equals("asc") || str.equals("desc"))) {// Comprobar
	                    // Puede que sea un campo
	                    strField = this.QualifyField(strField);
	                 }// Comprobar
	                 // Adicionar a la cadena de salida
	                 if (strTmp.contains("  "))
	                     strTmp = StringUtils.Replace(strTmp, "  ", " ");
	                 if (strTmp.contains(",,"))
	                     strTmp = StringUtils.Replace(strTmp, ",,", ",");
	                 strOut += (strField + strTmp);
	                 strField = "";
	                 strTmp = "";
	                 bFieldComplete = false;
	             }// Comprobar si es un operador
	         }// Cada caracter
	         if (bFieldComplete || !StringUtils.IsEmptyString(strField)) {// Comprobar si es un operador
	             str = strField.toLowerCase();
	             if (!(str.equals("asc") || str.equals("desc"))) {// Puede que sea un campo
	                 strField = this.QualifyField(strField);
	             }// Puede que sea un campo
	             // Adicionar a la cadena de salida
	             strOut += (strField + strTmp);
	             strField = "";
	             strTmp = "";
	             bFieldComplete = false;
	         }// Comprobar si es un operador
	         strIn = strOut;
	     }// Usa el SQL sin subqueries

	      return strIn;
	 }
	public async IsUnionQuery(Sentence?: SqlParser): Promise<boolean> {
		let parser = Sentence;

		if (parser == null) {
			// Trabajar con la de la colección
			if (this.m_parsedAccessString == null)
				if (!(await this.ParseAccessString())) {
					// Error
					// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
					////throw new Exception("No se puede interpretar la sentencia SQL de la colección '" + m_strName + "'");
					let sb = this.DataColl.GetMessage(
						XoneMessageKeys.SYS_MSG_COLL_SQLPARSEERROR_01,
						"{0} failed. Cannot parse SQL sentence for collection '{1}'"
					);
					sb = sb.replace("{0}", "CXoneDataCollection::IsUnionQuery");
					sb = sb.replace("{1}", this.DataColl.getName());
					throw new XoneGenericException(-10990, sb);
				} // Error
			parser = this.m_parsedAccessString;
		} // Trabajar con la de la colección
		if (parser == null) return false;
		// De lo contrario ver lo que dice el parser en sí y para sí
		return parser.getIsUnionQuery();
	}

	/**
	 * Parsea la cadena de acceso a datos de la colección
	 * @return				Devuelve TRUE si la cadena se parsea correctamente.
	 * @throws Exception
	 */
	public async ParseAccessString(): Promise<boolean> {
		// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
		this.m_parsedAccessString = new SqlParser(this.getConnection().getRowIdFieldName(), this.DataColl.getOwnerApp().getMessageHolder());

		// M09072901:	Permitir que se puedan traducir los joins a formato where en blackberry
		// Indicar que las sentencias con inner join tienen que traducirse
		/////m_parsedAccessString.setTranslateJoins(true);
		let s = this.DataColl.getAccessString();
		if (TextUtils.isEmpty(s)) return true;
		// Vamos a buscar las macros
		this.DataColl.ParseMacros(s);
		return true;
		// let b =
		//     this.m_parsedAccessString.ParseSqlString(s) == SqlType.SQLTYPE_SELECT;
		// // F10090805:	Si falla el parsing del SQL de una colección, eliminar el parser.
		// // Si falla el parsing tenemos que cargarnos el parser.
		// if (!b) this.clearParsedAccessString();
		// return b;
	}

	public clearParsedAccessString() {
		this.m_parsedAccessString = null;
	}

	public getParsedAccessString() {
		return this.m_parsedAccessString;
	}

	protected GenerateRowID(): string {
		return this.getConnection().GenerateRowId();
	}

	/**
	 * Devuelve el nombre real de un campo dado su nombre interno. La mayoría de los campos no están mapeados y devuelven el mismo nombre pasado como parámetro.
	 * @param FieldName			Nombre del campo que se quiere mapear
	 * @param DatabaseField		TRUE si se trata de un campo de base de datos. En este caso si tiene espacios se encierra en separadores.
	 * @return					Devuelve el nombre del campo mapeado.
	 */
	public MapField(FieldName: string, DatabaseField: boolean = false): string {
		let strFldName = FieldName;
		// Si resulta que el nombre de campo que están pasando es nulo o vacío no hacemos nada
		if (StringUtils.IsEmptyString(strFldName)) return FieldName;
		// De lo contrario ya se puede buscar en la lista...
		if (this.m_lstFieldMappings.containsKey(FieldName)) strFldName = this.m_lstFieldMappings.get(FieldName);
		// Comprobar si tiene espacios y encerrarlo entre caracteres delimitadores
		if (strFldName.indexOf(" ") != -1 && DatabaseField) strFldName = this.getConnection().QuoteFieldName(strFldName);
		// Lo que sea
		return strFldName;
	}

	/**
	 * Dada una cadena SQL la prepara sustituyendo las macros que contiene
	 * F12042601: PrepareSqlString no delega en la aplicación cuando se llama a la colección.
	 * Incluir los parámetros que le pasamos al PrepareSqlString de la conexión.
	 *
	 * @param Sentence			Sentencia SQL que se quiere preparar (sustituir macros y demás)
	 * @return					Devuelve la cadena con las macros sustituidas.
	 * @throws Exception
	 */
	public PrepareSqlString(Sentence: string, ChkGroup: boolean = false, Prefiltered: boolean = false): string {
		// Cadena vacía, no hacemos nada
		if (StringUtils.IsEmptyString(Sentence)) return Sentence;
		// No macros, tampoco
		if (!Sentence.contains("##")) return Sentence;
		// No conexión, tampoco nada
		if (this.getConnection() == null) return Sentence;
		// Sustituir cosillas propias de la colección
		let strOut = Sentence;
		if (strOut.contains("##FILTER##")) {
			// Filtro
			strOut = StringUtils.Replace(strOut, "##FILTER##", StringUtils.SafeToString(this.DataColl.getFilter())); // Filtro
			if (!strOut.contains("##")) return strOut;
		} // Filtro
		if (strOut.contains("##LINKFILTER##")) {
			// Filtro de enlace
			strOut = StringUtils.Replace(strOut, "##LINKFILTER##", StringUtils.SafeToString(this.DataColl.getLinkFilter())); // Filtro de enlace
			if (!strOut.contains("##")) return strOut;
		} // Filtro de enlace
		// ##ACCESS_STRING## completa, por si las moscas...
		if (strOut.contains("##ACCESS_STRING##")) {
			// Cadena de acceso
			strOut = StringUtils.Replace(
				strOut,
				"##ACCESS_STRING##",
				Utils.EMPTY_STRING
				// Luis: Esto hay que ver si es necesario StringUtils.SafeToString(this.DataColl.getAccessString())
			);
			if (!strOut.contains("##")) return strOut;
		} // Cadena de acceso
		// Si tiene ROWID, generarlo
		if (strOut.contains("##ROWID##")) strOut = StringUtils.Replace(strOut, "##ROWID##", StringUtils.SafeToString(this.GenerateRowID())); // Generar un ROWID

		// Finalmente, lo que quiera amarosa
		// A11070101: Incluir la evaluación de operadores custom (BIT) en las sentencias SQL.
		let cd = this.getConnection();
		// F12042601: PrepareSqlString no delega en la aplicación cuando se llama a la colección.
		// Pasar los parámetros a la conexión
		strOut = cd.PrepareSqlString(strOut, ChkGroup, Prefiltered);
		if (strOut.contains("##BIT##")) strOut = cd.ReplaceCustomOper(this.DataColl, strOut, "##BIT##");
		// F12042601: PrepareSqlString no delega en la aplicación cuando se llama a la colección.
		if (strOut.contains("##")) strOut = this.DataColl.getOwnerApp().PrepareSqlString(strOut);
		return strOut;
	}

	/**
	 * Dada una sentencia o cadena cualquiera, desarrolla en ella las macros de filtros
	 * @param Sentence			Sentencia SQL o cadena cuyas macros se quieren desarrollar.
	 * @return					Devuelve la cadena con las macros de filtro desarrolladas.
	 */
	private DevelopFilterMacros(Sentence: string): string {
		let strTmp = "";
		// UN CAMBEO
		// En este momento se pueden sustituir otros valores
		// para las macros de fecha y esas cosas
		if (StringUtils.IsEmptyString(Sentence)) return Sentence; // No hay nada que hacer
		// Sacar una copia para sustituir las macros
		let strSrcFilter = Sentence;
		if (strSrcFilter.contains("##")) {
			// Solo si hay algo en el filtro
			if (strSrcFilter.contains("##NOW")) {
				// Macros de fecha
				let dt = Calendar.getInstance();
				// Primero la fecha con hora
				if (strSrcFilter.contains("##NOW_TIME##")) {
					// Con hora
					strTmp = this.DevelopObjectValue(dt);
					strSrcFilter = StringUtils.Replace(strSrcFilter, "##NOW_TIME##", strTmp);
				} // Con hora
				//
				if (strSrcFilter.contains("##NOW")) {
					// Sin hora
					//
					ObjUtils.ZeroCalendarTime(dt);
					strTmp = this.DevelopObjectValue(dt);
					// Quitar la hora nula de la macro
					// K10052501:	Con SQLite hay que incluir siempre la hora en las fechas.
					// Quitar la búsqueda y sustitución de la cadena de hora cero...
					////if (strTmp.indexOf(" 00:00:00") !=-1)
					////{// Tiene la hora nula, quitarla
					////    strTmp = StringUtils.Replace(strTmp, " 00:00:00", "").trim();
					////}// Tiene la hora nula, quitarla
					if (strSrcFilter.contains("##NOW_NOTIME##")) strSrcFilter = StringUtils.Replace(strSrcFilter, "##NOW_NOTIME##", strTmp);
					if (strSrcFilter.contains("##NOW##")) strSrcFilter = StringUtils.Replace(strSrcFilter, "##NOW##", strTmp);
				} // Sin hora
			} // Macros de fecha
		} // Solo si hay algo en el filtro
		return strSrcFilter;
	}

	/**
	 * Dada una sentencia o cadena cualquiera, desarrolla los filtros de colección incluidos en ella
	 * @param Sentence			Sentencia SQL o cadena que se quiere desarrollar.
	 * @return					Devuelve una cadena con los filtros ya desarrollados.
	 * @throws Exception
	 */
	private DevelopCollFilters(Sentence: string): string {
		let str = "",
			strColl = "",
			strUserIDColl = "",
			strTmp = "";
		let ent: XoneDataObject;

		if (!Sentence.contains("##")) return Sentence; // Nada que hacer
		// Sacar una copia para efectuar las sustituciones...
		let strFilter = Sentence;
		if (
			strFilter.contains("##ENTID##") ||
			strFilter.contains("##ENTIDCOLL##") ||
			strFilter.contains("##USERID##") ||
			strFilter.contains("##USERIDCOLL##") ||
			strFilter.contains("##ENTIDLEVEL##") ||
			strFilter.contains("##ENTIDOWNER##")
		) {
			// Macros de empresa o usuario
			if (null != (ent = this.DataColl.getOwnerApp().getCurrentCompany())) {
				// Tiene empresa
				str = "=" + ent.GetObjectIdString(true);
				//
				// No es necesario incluir el ID de la propia empresa porque ya viene en el EntIDColl
				// De paso se comprueba que en caso de tratarse de un solo ID, no se incluya en un IN
				// sino que sea solo una igualdad...
				// Cuando se analiza el valor de la macro de ENTIDCOLL o ENTIDLEVEL hay que considerarla
				// valor único solamente si no tiene espacios, pues puede ser cualquier cosa mandada de
				// fuera... ante la duda usar IN
				if (!StringUtils.IsEmptyString(this.DataColl.getOwnerApp().getIdColl())) {
					// Tiene algo
					strTmp = this.DataColl.getOwnerApp().getIdColl();
					if (strTmp.indexOf(",") == -1 && strTmp.indexOf(" ") == -1) strColl = "=" + strTmp;
					else strColl = " IN (" + strTmp + ") ";
				} // Tiene algo
				else strColl = str;
				if (!StringUtils.IsEmptyString(this.DataColl.getOwnerApp().getUserIdColl()))
					strUserIDColl = " IN (" + this.DataColl.getOwnerApp().getUserIdColl() + ")";
				else strUserIDColl = " IS NULL";

				strFilter = StringUtils.Replace(strFilter, "=##ENTID##", str);
				strFilter = StringUtils.Replace(strFilter, "=##ENTIDCOLL##", strColl);
				strFilter = StringUtils.Replace(strFilter, "=##USERIDCOLL##", strUserIDColl);
				// Buscar la colección de empresas al mismo nivel y por esta rama hacia arriba
				if (strFilter.contains("##ENTIDLEVEL##")) {
					// Tiene esta macro
					if (!StringUtils.IsEmptyString(this.DataColl.getOwnerApp().getEntIdLevel())) {
						// Tiene algo
						strTmp = this.DataColl.getOwnerApp().getEntIdLevel();
						if (strTmp.indexOf(",") == -1 && strTmp.indexOf(" ") == -1) strColl = "=" + strTmp;
						else strColl = " IN (" + strTmp + ") ";
					} // Tiene algo
					else strColl = str;
					//
					// Ahora hacer la sustitución
					strFilter = StringUtils.Replace(strFilter, "=##ENTIDLEVEL##", strColl);
				} // Tiene esta macro
				// Buscar la macro
				if (strFilter.contains("##ENTIDOWNER##")) {
					// Tiene esta macro
					strTmp = this.DataColl.getOwnerApp().getEntIdOwner();
					if (!StringUtils.IsEmptyString(strTmp)) strColl = "=" + strTmp;
					// Tiene algo
					else strColl = str;
					// Ahora hacer la sustitución
					strFilter = StringUtils.Replace(strFilter, "=##ENTIDOWNER##", strColl);
				} // Tiene esta macro
				//
				strFilter = StringUtils.Replace(strFilter, '"', "'");
				// Usuario
				let n = NumberUtils.SafeToInt(ent.GetRawPropertyValue("MAP_IDCURRENTUSER"));
				if (n == 0) str = " IS NULL";
				else str = "=" + n;
				strFilter = StringUtils.Replace(strFilter, "=##USERID##", str);
			} // Tiene empresa
		} // Macros de empresa o usuario
		//
		// El MID si está replicando
		if (strFilter.contains("##MID##")) {
			// El MID solo si está replicando
			if (this.getConnection().getIsReplicating()) {
				// Está replicando
				str = "'" + this.getConnection().getMID() + "'";
				strFilter = StringUtils.Replace(strFilter, "##MID##", str);
			} // Está replicando
		} // El MID solo si está replicando
		//
		if (strFilter.contains("##ID##")) {
			// Solo si tiene que sustituirlo
			if (this.DataColl.getOwnerObject() != null) {
				// Objeto propietario
				str = this.DataColl.getOwnerObject().GetObjectIdString(true);
				strFilter = StringUtils.Replace(strFilter, "=##ID##", str.equals("NULL") ? " IS NULL" : "=" + str);
			} // Objeto propietario
		} // Solo si tiene que sustituirlo
		//
		// Si dentro de algún filtro hay operadores bitwise, comprobar los datos
		// en el nodo DBMS para sustituir por la operación correcta
		strFilter = this.getConnection().ReplaceCustomOper(this.DataColl, strFilter, "##BIT##");

		return strFilter;
	}

	//#endregion
	/**
	 * Desarrolla el valor de un objeto y lo devuelve como cadena preparada para colocar en una sentencia SQL o filtro.
	 *
	 * K12102201: Modificación de DevelopObjectValue para pasar ForceNulls como parámetro.
	 * Un parámetro para indicar si se fuerzan los nulos o no.
	 *
	 * @param Value			Valor que se quiere desarrollar.
	 * @return				Devuelve el valor desarrollado como cadena con sus comillas y demás.
	 */
	public DevelopObjectValue(Value: object, ForceNulls: boolean = true): string {
		return this.getConnection().DevelopObjectValue(Value, ForceNulls);
	}
	//#region Preparar busquedas en el SQL
	/**
	 * M09072903:	Programar un mecanismo de restauración de campos para SQLs sin unión.
	 * Dada una cadena de búsqueda se arma un SQL para ejecutar y buscar una o varias filas que
	 * correspondan a dicha clave. Utilizado para las búsquedas de objetos en base de datos.
	 * @param KeySearch			Clave que contiene los posibles filtros de búsqueda del elemento en base de datos.
	 * @return					Devuelve una sentencia SELECT Con la sintaxis adecuada y la clave pasada en su lugar correcto.
	 * @throws Exception
	 */
	public async GenerateSearchSql(KeySearch: string): Promise<string> {
		let strCmd: string;
		if (this.Options.bUseRaw) {
			// No permite subqueries
			// F12042504: Una colección con un sql desconocido no debería generar sql de búsqueda.
			// Esto está hecho en PDA, habrá que documentar
			if (!this.Options.bParseSQL) return this.DataColl.getAccessString();
			if (this.m_parsedAccessString == null) if (!(await this.ParseAccessString())) return "";
			if (this.m_parsedAccessString == null) strCmd = Utils.EMPTY_STRING;
			else if (this.m_parsedAccessString.GetSqlType() == SqlType.SQLTYPE_UNKNOWN) strCmd = Utils.EMPTY_STRING;
			else strCmd = await this.EmbedFilters(KeySearch);
		} // No permite subqueries
		else {
			// Permite subqueries
			// F12112103: Cuando se usan subqueries no se está generando el SQL adecuado.
			// Arreglar esto cuando hay subqueries.
			if (this.Options.bSingleObject) strCmd = "SELECT * FROM " + this.DataColl.getAccessString() + " rrss WHERE " + KeySearch;
			else strCmd = "SELECT * FROM (" + this.DataColl.getAccessString() + ") rrss WHERE " + KeySearch;
		} // Permite subqueries
		return strCmd;
	}

	/**
	 * M09072903:	Programar un mecanismo de restauración de campos para SQLs sin unión.
	 * Esta función genera una cadena de búsqueda para efectuar una búsqueda en base de datos.
	 * Incluye los filtros, las macros de búsqueda y demás.
	 * @param FieldName			Nombre del campo por el que se va a armar la clave de búsqueda.
	 * @param FieldValue		Valor que se quiere buscar.
	 * @return					Devuelve una cadena que se puede emplear para armar el SQL de búsqueda.
	 * @throws Exception
	 */
	public async GenerateKeySearch(FieldName: string, FieldValue: Object): Promise<string> {
		let strKeySearch: string;
		let strField = this.QualifyField(FieldName);
		if (FieldName.equals(this.DataColl.getIdFieldName())) {
			strKeySearch = strField + "=" + this.FormatKeyValue(FieldValue.toString());
			// O10082401: Cuando el KeySearch es el campo clave no hacen falta más filtros.
			return strKeySearch;
		} else {
			// No es clave primaria
			strKeySearch = strField + "=" + this.DevelopObjectValue(FieldValue); // Formatear la búsqueda
			// O10052502:	Al armar la clave de búsqueda tener en cuenta la clave.
			if (this.DataColl.IsLinkedField(FieldName)) {
				// Si es un campo entero y el valor es cero, podría comparar con NULL también
				if (this.DataColl.FieldPropertyValue(FieldName, "type").equals("N")) {
					// Convertir y preparar la cadenilla
					try {
						if (NumberUtils.SafeToInt(FieldValue) == 0) strKeySearch = "((" + strKeySearch + ") OR (" + strField + " IS NULL))";
					} catch (x) {
						// Ignorar
					}
				} // Convertir y preparar la cadenilla
			} // Si es un campo entero y el valor es cero, podría comparar con NULL también
		} // No es clave primaria
		// O10052502:	Al armar la clave de búsqueda tener en cuenta la clave.
		// Incluir las declaraciones y llamada a ApplyFilters dentro de la condición
		if (StringUtils.IsEmptyString(this.Options.strLookupMacroName)) {
			// Búsqueda sin macro de búsqueda
			let strFilters: string = await this.ApplyFilters(false);
			if (!StringUtils.IsEmptyString(strFilters)) {
				// Concatenar ambos
				strKeySearch = strKeySearch + " AND (" + strFilters + ")";
			} // Concatenar ambos
		} // Búsqueda sin macro de búsqueda
		return strKeySearch;
	}

	/**
	 * Formatea y devuelve el valor de una clave de búsqueda de la colección en función de si es numérica o de cadena.
	 * @param Key			Clave que se quiere formatear.
	 * @return				Devuelve la clave formateada en función de si la colección tiene clave numérica o no.
	 */
	private FormatKeyValue(Key: string): string {
		if (this.DataColl.getStringKey()) return "'" + Key + "'";
		// De lo contrario, tal cual
		return StringUtils.removeChars(Key, "'");
	}

	//#endregion

	//#region Para aplicar filtros a los queries

	/**
	 * @return			Devuelve TRUE si la cadena de acceso a datos de la colección tiene ya un filtro (WHERE, HAVING o algo por el estilo)
	 * @throws Exception
	 */
	public async IsSqlPrefiltered(): Promise<boolean> {
		if (!this.Options.bParseSQL) return false;
		// Si no está parseado el SQL, tendremos que parsearlo
		if (this.m_parsedAccessString == null || this.m_parsedAccessString.GetSqlType() == 0)
			if (!(await this.ParseAccessString())) {
				// Error
				// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
				////throw new Exception("No se puede interpretar la sentencia SQL de la colección '" + m_strName + "' para XoneDataCollection::IsSqlPrefiltered");
				let sb = this.DataColl.GetMessage(
					XoneMessageKeys.SYS_MSG_COLL_SQLPARSEERROR_01,
					"{0} failed. Cannot parse SQL sentence for collection '{1}'"
				);
				sb = sb.replace("{0}", "CXoneDataCollection::IsSqlPrefiltered");
				sb = sb.replace("{1}", this.DataColl.getName());
				throw new XoneGenericException(-6631, sb);
			} // Error
		// Buscar si ya está filtrado
		// Si tiene WHERE, entonces está prefiltrado
		if (!StringUtils.IsEmptyString(this.m_parsedAccessString.GetWhereSentence())) return true;
		// Si tiene HAVING entonces también está prefiltrado
		if (!StringUtils.IsEmptyString(this.m_parsedAccessString.getHavingSentence())) return true;
		// De lo contrario podría ser una unión
		if (this.m_parsedAccessString.getIsUnionQuery()) {
			// Analizar cada posible registro de la unión
			for (let i = 0; i < this.m_parsedAccessString.getUnionQueries().length; i++) {
				// Basta con que uno de ellos está prefiltrado para considerar que lo está del todo
				let sql = this.m_parsedAccessString.getUnionQueries()[i];
				if (!StringUtils.IsEmptyString(sql.GetWhereSentence())) return true;
				if (!StringUtils.IsEmptyString(sql.getHavingSentence())) return true;
			} // Basta con que uno de ellos está prefiltrado para considerar que lo está del todo
		} // Analizar cada posible registro de la unión
		// Finalmente es que no tenemos prefiltado ninguno...
		return false;
	}

	/// Devuelve una sentencia SQL con los filtros de colección incluidos donde vayan dentro de ella (empleado para los DBMS que no usan subqueries)
	/// <param name="Sentence">Sentencia SQL en la que se quieren incluir los filtros. NULL para usar la cadena de acceso de datos de la colección.</param>
	/// <param name="Filter">Filtros que se quieren incluir dentro de la sentencia SQL</param>
	public async EmbedFilters(Sentence: SqlParser | string, Filter?: string): Promise<string> {
		let sentence: SqlParser = null;
		if (typeof Sentence == "string") {
			Filter = Sentence as string;
			Sentence = null;
		} else {
			sentence = Sentence;
		}
		const FunctionName = "CXoneDataCollection::EmbedFilters";
		// Ver si nos han mandado los filtros. De lo contrario usamos los nuestros
		let strFilter = StringUtils.IsEmptyString(Filter) ? await this.ApplyFilters(false) : Filter;
		// Ahora ver si nos mandan una sentencia en la cual insertar los filtros
		if (sentence == null) {
			// Obtener la de la colección
			if (this.m_parsedAccessString == null)
				if (!(await this.ParseAccessString())) {
					// Error
					// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
					////throw new XoneGenericException(-1278, "No se puede interpretar la sentencia SQL de la colección '" + m_strName + "'");
					let sb = this.DataColl.GetMessage(
						XoneMessageKeys.SYS_MSG_COLL_SQLPARSEERROR_01,
						"{0} failed. Cannot parse SQL sentence for collection '{1}'"
					);
					sb = sb.replace("{0}", FunctionName);
					sb = sb.replace("{1}", this.DataColl.getName());
					throw new XoneGenericException(-1278, sb);
				} // Error
			sentence = this.m_parsedAccessString;
		} // Obtener la de la colección
		// Si no hay filtros que aplicar, no hacemos nada
		if (StringUtils.IsEmptyString(strFilter)) return sentence.RegenerateSql();
		// Ahora habrá que trabajar diferente en función de si es una unión o no...
		let output = new SqlParser(sentence);
		if (!output.CopyData(sentence)) {
			// Error
			// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
			////throw new XoneGenericException(-1279, "Error introduciendo los filtros en la sentencia SQL de la colección '" + m_strName + "'");
			let sb = this.DataColl.GetMessage(XoneMessageKeys.SYS_MSG_COLL_EMBEDFILTERFAIL, "{0} failed. Error embedding filters in collection '{1}'");
			sb = sb.replace("{0}", FunctionName);
			sb = sb.replace("{1}", this.DataColl.getName());
			throw new XoneGenericException(-1279, sb);
		} // Error
		// Ahora meter aquí las cosillas
		let strWhere: string;
		if (output.getIsUnionQuery()) {
			// Filtros a cada query de la unión
			for (let i = 0; i < output.getUnionQueries().length; i++) {
				// Cada subquery
				let uq = output.getUnionQueries()[i];
				if (!StringUtils.IsEmptyString(uq.GetWhereSentence())) strWhere = "(" + uq.GetWhereSentence() + ") AND " + strFilter;
				else strWhere = strFilter;
				uq.SetWhereSentence(strWhere);
			} // Cada subquery
		} // Filtros a cada query de la unión
		else {
			// Un solo filtro
			// F12042503: Las sentencias que tienen grup by deben usar having en su filtro.
			// Las sentencias agrupadas llevan having
			let strPrevFilter: string;
			let bGroup = !TextUtils.isEmpty(output.getGroupBySentence());
			if (bGroup) strPrevFilter = output.getHavingSentence();
			else strPrevFilter = output.GetWhereSentence();
			// Concatenar o no
			if (!StringUtils.IsEmptyString(strPrevFilter)) strWhere = "(" + strPrevFilter + ") AND " + strFilter;
			else strWhere = strFilter;
			if (bGroup) {
				output.setHavingSentence(strWhere);
				output.SetWhereSentence(Utils.EMPTY_STRING);
			} else {
				output.SetWhereSentence(strWhere);
				output.setHavingSentence(Utils.EMPTY_STRING);
			}
		} // Un solo filtro
		// Devolver la sentencia ya arreglada
		return output.RegenerateSql();
	}
	/**
	 * @param IncludeWhere			TRUE si además hay que devolverlo con la clave WHERE y listo para usarse como filtro.
	 * @param Subquery				Sentencia SQL que se usará para cualificar los campos. NULL para usar la cadena de acceso de la colección.
	 * @return						Devuelve una cadena con los filtros de la colección ya desarrollados y con las macros sustituidas.
	 * @throws Exception
	 */
	private async ApplyFilters(IncludeWhere: boolean, Subquery: SqlParser = null): Promise<string> {
		let strTmp = "",
			str = "";

		if (!StringUtils.IsEmptyString(this.DataColl.getLinkFilter())) str = "(" + (await this.PrepareFilter(this.DataColl.getLinkFilter())) + ")"; // Preparar y devolver el filtro

		if (!StringUtils.IsEmptyString(this.DataColl.getFilter())) {
			// Filtro normal
			if (!StringUtils.IsEmptyString(str)) str += " AND ";
			strTmp = "(" + (await this.PrepareFilter(this.DataColl.getFilter(), Subquery)) + ")";
			str += strTmp;
		} // Filtro normal
		if (!StringUtils.IsEmptyString(str)) {
			// Preparar el WHERE
			if (IncludeWhere) str = " WHERE (" + str + ") ";
		} // Preparar el WHERE
		if (!StringUtils.IsEmptyString(str)) {
			// Si no es vacia
			// Si lleva macros sustituirlas
			str = this.DevelopCollFilters(str);
			str = this.DevelopFilterMacros(str);
			str = await this.DataColl.EvaluateAllMacros(str, true);
			//
			// Si la colección es un contents pasarle al propietario pa que evalúe las macros de campos
			/*
                    Ocurre que desde que los filtros se evalúan siempre dinámicamente es necesario
                    reevaluar incluso los filtros de los contents, sobre todo porque cuando se crean
                    objetos nuevos hay que poner los valores por defecto o los que vienen de las macros
                    de campos
                */
			if (this.DataColl.getOwnerObject() != null) str = this.DataColl.getOwnerObject().PrepareSqlString(str);
			// A11070101: Incluir la evaluación de operadores custom (BIT) en las sentencias SQL.
			// F12042601: PrepareSqlString no delega en la aplicación cuando se llama a la colección.
			else str = this.PrepareSqlString(str, false, false);
			/*
                    Puede ser que después de todo esto quedaran macros sin sustituir, por lo que
                    en caso de tratarse de una colección de contents que está dentro de otro objeto
                    se puedan resolver estas macros en la colección propietaria del otro objeto
                */
			if (str.contains("##") && this.DataColl.getOwnerObject() != null) {
				// Quedan cosas
				str = await this.DataColl.getOwnerObject().getOwnerCollection().EvaluateAllMacros(str, true);
			} // Quedan cosas
		} // Si no es vacia

		return str;
	}

	/**
	 * @param IncludeWhere			TRUE si además hay que devolverlo con la clave WHERE y listo para usarse como filtro.
	 * @param Subquery				Sentencia SQL que se usará para cualificar los campos. NULL para usar la cadena de acceso de la colección.
	 * @return						Devuelve una cadena con los filtros de la colección ya desarrollados y con las macros sustituidas.
	 * @throws Exception
	 */
	public async ResolveFilters(IncludeWhere: boolean, Subquery: SqlParser = null, keySearch: string = null): Promise<string> {
		let strTmp = "",
			str = "";

		if (!StringUtils.IsEmptyString(this.DataColl.getLinkFilter())) str = "(" + (await this.PrepareFilter(this.DataColl.getLinkFilter())) + ")"; // Preparar y devolver el filtro

		if (keySearch) {
			if (!StringUtils.IsEmptyString(str)) str += " AND ";
			strTmp = "(" + keySearch + ")";
			str += strTmp;
		} else if (!StringUtils.IsEmptyString(this.DataColl.getFilter())) {
			// Filtro normal
			if (!StringUtils.IsEmptyString(str)) str += " AND ";
			strTmp = "(" + (await this.PrepareFilter(this.DataColl.getFilter(), Subquery)) + ")";
			str += strTmp;
		} // Filtro normal
		if (!StringUtils.IsEmptyString(str)) {
			// Preparar el WHERE
			if (IncludeWhere) str = " WHERE (" + str + ") ";
		} // Preparar el WHERE
		if (!StringUtils.IsEmptyString(str)) {
			// Si no es vacia
			// Si lleva macros sustituirlas
			str = this.DevelopCollFilters(str);
			str = this.DevelopFilterMacros(str);
			str = await this.DataColl.EvaluateAllMacros(str, true);
			//
			// Si la colección es un contents pasarle al propietario pa que evalúe las macros de campos
			/*
                    Ocurre que desde que los filtros se evalúan siempre dinámicamente es necesario
                    reevaluar incluso los filtros de los contents, sobre todo porque cuando se crean
                    objetos nuevos hay que poner los valores por defecto o los que vienen de las macros
                    de campos
                */
			if (this.DataColl.getOwnerObject() != null) str = this.DataColl.getOwnerObject().PrepareSqlString(str);
			// A11070101: Incluir la evaluación de operadores custom (BIT) en las sentencias SQL.
			// F12042601: PrepareSqlString no delega en la aplicación cuando se llama a la colección.
			else str = this.PrepareSqlString(str, false, false);
			/*
                    Puede ser que después de todo esto quedaran macros sin sustituir, por lo que
                    en caso de tratarse de una colección de contents que está dentro de otro objeto
                    se puedan resolver estas macros en la colección propietaria del otro objeto
                */
			if (str.contains("##") && this.DataColl.getOwnerObject() != null) {
				// Quedan cosas
				str = await this.DataColl.getOwnerObject().getOwnerCollection().EvaluateAllMacros(str, true);
			} // Quedan cosas
		} // Si no es vacia

		return str;
	}

	/**
	 * Prepara el filtro que se pasa como parámetro cualificando sus campos y demás.
	 * @param Filter				Filtro de datos que se quiere preparar.
	 * @param Subquery				Sentencia SQL parseada que se usará para cualificar los campos del filtro.
	 * @return						Devuelve el filtro ya preparado con los campos cualificados y demás.
	 * @throws Exception
	 */
	public async PrepareFilter(Filter: string, Subquery?: SqlParser): Promise<string> {
		let strField = "",
			str = "";
		let strOut = new StringBuilder();
		let strTmp = new StringBuilder();
		let c = "";
		let i = 0,
			nCount = 0;
		let bIsField = false,
			bFieldComplete = false,
			bIsTextToken = false;

		if (StringUtils.IsEmptyString(Filter)) return Filter;
		// Si no usa el SQL sin subqueries no es necesario hacer ningún preprocesamiento
		// del filtro, sino que se usa tal cual... ventajas de los DBMS decentes...
		if (!this.Options.bUseRaw) return Filter;
		// O11112401: Aquellas colecciones que usan una sola tabla no tienen que cualificar.
		// Si tenemos un sql de una sola tabla entonces no tenemos que cualificar los campos
		if (Subquery == null) {
			// Usar la parseada
			if (this.m_parsedAccessString == null) await this.ParseAccessString();
			// Usarla si la tenemos...
			Subquery = this.m_parsedAccessString;
		} // Usar la parseada
		if (Subquery != null) {
			// Tiene subquery
			let table = Subquery.getTableFrom();
			if (table != null) {
				// Tabla
				if (null == table.getSecondTable()) return Filter;
			} // Tabla
		} // Tiene subquery
		// Ahora debe parsear el filtro, para ello usamos nuestro viejo formulaparser
		// empleando también como operadores los lógicos.
		for (i = 0, nCount = Filter.length, bIsField = bFieldComplete = bIsTextToken = false; i < nCount; i++) {
			// Recorrer el filtro
			switch ((c = Filter.charAt(i))) {
				case " ": // Espacios
				case "=": // Operadores
				case ">":
				case "<":
				case "(":
				case ")":
				case "," /* F10052503:	Incluir la coma como separador en PrepareFilter. */:
					if (bIsField && !bIsTextToken) {
						// Se acabó el campo
						strTmp = new StringBuilder();
						for (; i < nCount; strTmp.append(Filter.charAt(i)), i++) if (Filter.charAt(i) == " " || !this.IsClosingToken(Filter.charAt(i))) break;
						for (; i < nCount; strTmp.append(Filter.charAt(i)), i++) if (Filter.charAt(i) != " ") break;
						bFieldComplete = true;
						bIsField = false;
						i--;
					} // Se acabó el campo
					else strOut.append(c);
					break;
				case "'":
					/*
                                Comprobar si está dentro de un literal de cadena, en cuyo caso no habrá que
                                realizar cualificaciones de ningún tipo
                            */
					// F11112806: Problema con los filtros con operadores raros en sqlite.
					// Si estamos trabajando con un campo (szField tiene valor) entonces posiblemente fuera un operador, así
					// que lo que tenemos que hacer es ponerlo a la salida y abrir el literal de texto
					if (!StringUtils.IsEmptyString(strField)) {
						// Tiene valor
						strOut.append(strField);
						strField = Utils.EMPTY_STRING;
						bIsField = bFieldComplete = false;
					} // Tiene valor
					bIsTextToken = !bIsTextToken;
					strOut.append(c);
					break;
				default:
					if (bIsField) strField += c;
					else {
						// No contar este
						if (bIsTextToken) strOut.append(c);
						else {
							// No es un token de texto
							bIsField = true;
							i--;
						} // No es un token de texto
					} // No contar este
					break;
			}
			//
			// Si ha terminado un campo procesarlo
			if (bFieldComplete) {
				// Comprobar si es un operador
				str = strField.toLowerCase();
				if (!(str.equals("is") || str.equals("and") || str.equals("or") || str.equals("in") || str.equals("between") || str.equals("not"))) {
					// Comprobar
					// Puede que sea un campo
					strField = this.QualifyField(strField, Subquery);
				} // Comprobar
				// Adicionar a la cadena de salida
				strOut.append(strField);
				strOut.append(strTmp.toString());
				strField = "";
				bFieldComplete = false;
			} // Comprobar si es un operador
		} // Recorrer el filtro
		//
		strOut.append(strField);
		return strOut.toString();
	}

	/**
	 * TRUE si el parámetro es un token que cierra una expresión
	 * @param Token			Elemento que se quiere comprobar.
	 * @return				Devuelve TRUE si el parámetro vale para cerrar una expresión.
	 */
	private IsClosingToken(Token: string): boolean {
		return Token == "=" || Token == ")" || Token == "=" || Token == ">" || Token == "<" || Token == " ";
	}
	//#endregion

	/**
	 * M09072903:	Programar un mecanismo de restauración de campos para SQLs sin unión.
	 * Esta función tiene un objetivo bastante similar al de GetObject pero con algunas diferencias, a saber:
	 * 1- Siempre busca en base de datos, por lo que no es necesario indicar un parámetro para eso.
	 * 2- Siempre usa los filtros que tenga la colección, por lo que tampoco hace falta parámetro para eso.
	 *
	 * @param FieldName			Nombre del campo por el que se va a hacer la búsqueda.
	 * @param FieldValue		Valor que se quiere usar como comparación.
	 * @return					Devuelve un resultset con la línea correspondiente al elemento a buscar.
	 * @throws Exception
	 */
	public async GetResultSet(FieldName: string, FieldValue: any): Promise<IResultSet> {
		let strKeySearch = await this.GenerateKeySearch(FieldName, FieldValue);
		let strCmd = await this.GenerateSearchSql(strKeySearch);
		// Preparar esta cosa, porque traerá macros y demás
		// F12042601: PrepareSqlString no delega en la aplicación cuando se llama a la colección.
		if (strCmd.contains("##")) strCmd = this.PrepareSqlString(strCmd, false, await this.IsSqlPrefiltered());
		// Buscar en base de datos
		let rs = await this.getConnection().CreateRecordsetAsync(strCmd, {
			count: false,
			coll: this.DataColl.getName(),
			macros: this.DataColl.getMacros(),
			loadall: false,
            // where : strKeySearch,
			page: {},
		});
		// Moverse para el primero, a fin de cuentas es lo que nos hace falta...
		await rs.next();
		return rs;
	}

	/**
	 * @param FieldName		Valor que se quiere comparar. Si es un nombre de campo la función devuelve FALSE.
	 * @return				Devuelve TRUE si la cadena que se pasa como parámetro NO es un nombre de campo.
	 */
	private IsNotFieldName(FieldName: string): boolean {
		let strInvalid = " /{}\\,;\r\n\t:#";

		for (let i = 0; i < strInvalid.length; i++) {
			// Analiza cada caracter
			let c = strInvalid.charAt(i);
			if (FieldName.indexOf(c) != -1) return true;
		} // Analiza cada caracter
		// De lo contrario posiblemente sea un campo
		return false;
	}

	QualifyField(FieldName: any, Sentence?: SqlParser): string {
		let str: string;

		// Primero ver si no hay que hacer nada
		if (!this.Options.bUseRaw || StringUtils.IsEmptyString(FieldName)) return FieldName;
		// Si no es un nombre de campo, tampoco devolvemos nada...
		if (this.IsNotFieldName(FieldName)) return FieldName;
		// Ante todo ver si ya está cualificado e insertado en una cache
		let cache: Hashtable<string, string> = null;
		if (null != (cache = this.DataColl.getOwnerApp().GetQualifyCache(this.DataColl.getName()))) {
			// Si tenemos cache, buscar
			// K13061901: Modificaciones para mejorar el soporte de concurrencia en la maquinaria.
			// Esto puede dar problemas si hay concurrencia...
			//synchronized (cache)
			{
				if (cache.containsKey(FieldName)) return cache.get(FieldName);
			}
		} // Si tenemos cache, buscar
		// F11070101: No cualificar campos especiales en las colecciones.
		// Los campos especiales no se cualifican
		// O12050702: Optimización al gestionas los campos que son de fórmula o bit.
		// Si es un campo fórmula o bit estará en la lista de tales.
		//////str =FieldPropertyValue (FieldName, "bit");
		//////if (!StringUtils.IsEmptyString(str))
		if (this.DataColl.BitProps?.contains(FieldName)) return FieldName;
		//////str =FieldPropertyValue (FieldName, "formula");
		//////if (!StringUtils.IsEmptyString(str))
		if (this.DataColl.FormulaProps?.contains(FieldName)) return FieldName;
		// En principio seguimos como antes...
		let strQualifier = this.DataColl.FieldPropertyValue(FieldName, "qualifier");
		if (!StringUtils.IsEmptyString(strQualifier)) {
			// Cachearlo y devolverlo
			// K13061901: Modificaciones para mejorar el soporte de concurrencia en la maquinaria.
			// Por si las concurrencias...
			if (cache != null) {
				// Tiene cache
				//synchronized (cache)
				{
					cache.put(FieldName, strQualifier);
				}
			} // Tiene cache
			return strQualifier;
		} // Cachearlo y devolverlo
		// Para cualificar un campo tenemos que tener el AccessString parseado
		// ya que es más eficiente buscar en la lista de campos que ponerse a parsear
		// cada vez que se vaya a cualificar un campo, algo que se hace bastante a menudo
		if (Sentence == null) {
			// Usar la cadena de conexión normal
			if (this.m_parsedAccessString == null) return FieldName;
			// if (!this.ParseAccessString())
			//     // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
			//     ////throw new Exception("No se puede interpretar la sentencia de selección de datos de la colección.");
			//     throw new XoneGenericException(
			//         -123343,
			//         this.DataColl.GetMessage(
			//             XoneMessageKeys.SYS_MSG_COLL_SQLPARSEERROR,
			//             "Cannot parse SQL sentence for the collection."
			//         )
			//     );
			// Usar esta
			Sentence = this.m_parsedAccessString;
		} // Usar la cadena de conexión normal
		if (ObjUtils.IsNothing(Sentence)) return FieldName;
		// Si el campo no está definido en la colección tenemos que ver si es especial.. de lo contrario
		// será un error porque no se puede cualificar un campo desonocido.
		if (!FieldName.equals(this.DataColl.strPk) && !this.DataColl.FieldExists(FieldName)) {
			// No existe y no es la clave
			if (FieldName.startsWith("MAP_")) {
				// No está definido en la colección
				str = "No se puede cualificar el campo '" + FieldName + "' porque no está definido en la colección '" + this.DataColl.getName() + "'";
				throw new Exception(str);
			} // No está definido en la colección
			// Si no es algún campo especial, devolverlo tal cual
			if (!FieldName.equals(this.m_connection.getRowIdFieldName()) && !FieldName.equals("OBJTIMESTAMP")) return FieldName;
		} // No existe y no es la clave
		let queryFields = Sentence.getQueryFields();
		// Ahora proceder con los campos en función de su nombre
		if (FieldName.startsWith("MAP_")) {
			// Campos MAP
			for (let i = 0; i < queryFields.length; i++) {
				// Comprobar los alias que son los que tienen lo que buscamos
				let f = queryFields[i];
				if (f.getAlias().equals(FieldName)) {
					// Cachear si aplica y devolver
					// K13061901: Modificaciones para mejorar el soporte de concurrencia en la maquinaria.
					// Por si las concurrencias...
					if (cache != null) {
						// Cache
						//synchronized (cache)
						{
							cache.put(FieldName, f.getName());
						}
					} // Cache
					return f.getName();
				} // Cachear si aplica y devolver
			} // Comprobar los alias que son los que tienen lo que buscamos
		} // Campos MAP
		else {
			// Campo directo
			str = "." + FieldName;

			for (let i = 0; i < queryFields.length; i++) {
				// Comprobar cómo termina cada nombre de campo
				let f = queryFields[i];
				if (f.getName().endsWith(str)) {
					// Cachear si aplica y devolver
					// F11041503: Problemas para cualificar campos cuando no tienen aliases.
					let result = null;
					if (StringUtils.IsEmptyString(f.getAlias())) result = f.getName();
					else {
						if (f.getAlias().compareToIgnoreCase(FieldName) == 0) result = f.getName();
					}
					if (!StringUtils.IsEmptyString(result)) {
						// K13061901: Modificaciones para mejorar el soporte de concurrencia en la maquinaria.
						// Por si hay concurrencia
						if (cache != null) {
							// Cache
							//synchronized (cache)
							{
								cache.put(FieldName, result);
							}
						} // Cache
						return result;
					}
				} // Cachear si aplica y devolver
			} // Comprobar cómo termina cada nombre de campo
		} // Campo directo
		// Ahora tenemos que ver si el SQL tiene x.*
		for (let i = 0; i < queryFields.length; i++) {
			// Ver si algún campo termina con .*
			let f = queryFields[i];
			if (f.getName().endsWith(".*")) {
				// Este vale
				str = f.getName().substring(0, f.getName().indexOf(".*")) + "." + FieldName;
				// K13061901: Modificaciones para mejorar el soporte de concurrencia en la maquinaria.
				// Por si hay concurrencia
				if (cache != null) {
					// Cache
					//synchronized (cache)
					{
						cache.put(FieldName, str);
					}
				} // Cache
				return str;
			} // Este vale
		} // Ver si algún campo termina con .*
		// Finalmente cualificar con la primera tabla... algo es algo
		let tbl: QueryTable;
		if (null != (tbl = Sentence.getTableFrom())) {
			// Obtener de la tabla solamente su alias
			if (!StringUtils.IsEmptyString((str = tbl.getAlias()))) {
				// Armar el cualificador
				str = str + "." + FieldName;
				// K13061901: Modificaciones para mejorar el soporte de concurrencia en la maquinaria.
				// Por si hay concurrencia...
				if (cache != null) {
					// Cache
					//synchronized (cache)
					{
						cache.put(FieldName, str);
					}
				} // Cache
				return str;
			} // Armar el cualificador
			// Armarlo con el nombre de la tabla
			// F11110811: QualifyField no debe usar el nombre completo de tabla en ningún caso.
			// Esto no por favor (el nombre de tabla no se usa como cualificador nunca)
			//////str = tbl.getActualName() + "." + FieldName;
			// F12060701: QualifyField no hace verificaciones antes de utilizar el valor por defecto.
			if (StringUtils.IsEmptyString(str)) str = FieldName;
			// K13061901: Modificaciones para mejorar el soporte de concurrencia en la maquinaria.
			// Por si hay concurrencia
			if (cache != null) {
				// Cache
				//synchronized (cache)
				{
					cache.put(FieldName, str);
				}
			} // Cache
			return str;
		} // Obtener de la tabla solamente su alias
		// Finalmente, si no hay nada, devolver el campo tal cual
		return FieldName;
	}

	public getCount(): number {
		return this.m_lstOrderedList.length;
	}

	/**
	 * Efectúa la limpieza real de la colección aunque esta está bloqueada
	 * @return		Devuelve TRUE si la colección se limpia correctamente.
	 */
	public Clear(): boolean {
		try {
			// Ahora limpiar las colecciones porsi...
			this.m_lstObjectList.clear();
			this.m_lstOrderedList = new Array<XoneDataObject>();
			if (this.m_lstCopyList != null) {
				this.m_lstCopyList = new Vector<XoneDataObject>();
				//this.m_lstCopyList = null;
			}
			this.m_lstObjectLru = new Vector<XoneDataObject>();
			//   this.m_bFull = this.m_bIsClearing = false;
			// Si es especial esto es como un EB
			if (this.m_bIsSpecial) this.m_nBrowseLength = -1;
		} catch (e) {
			// Ignorar las excepciones a menos que sea debug
			if (this.Options.bDebug) {
				// Imprimir
				//this.m_ownerApp.WriteConsoleString(e.getMessage ());
				console.log(e.message);
			} // Imprimir
		}
		return true;
	}

	//#region Load CurrentItem en StartBrowse
	/**
	 * Carga el objeto actual en un recorrido SB/EB
	 * @return		Devuelve TRUE si la carga del elemento actual del recorrido es exitosa
	 */
	private async LoadCurrentItem(): Promise<boolean> {
		let FunctionName = "CXoneDataCollection::LoadCurrentItem";
		let obj: XoneDataObject;
		let bClear = true,
			bReturn = false;

		try {
			//  Si es una colección especial, el recorrido
			//  StartBrowse-EndBrowse busca elementos en la
			//  lista normal no en la base de datos...
			if (this.m_bIsSpecial) {
				// Especial
				this.m_pCurrentItem = null;
				if (this.m_nBrowseLength < 0) this.m_nBrowseLength = this.m_lstOrderedList.length;
				if (this.m_nBrowseLength == 0 || this.m_nCurrentIndex < 0) return true;
				this.m_pCurrentItem = await this.GetObject(this.m_nCurrentIndex);
				return true;
			} // Especial
			// Si ya hay uno y tenemos que usarlo para todo el recorrido indicar que no hay que marcar
			if (this.m_pCurrentItem != null) if (this.m_bDeferredLoad) bClear = false;
			if (this.m_rs == null) bClear = bReturn = true;
			// No hay recorrido activo
			else {
				// Hay recorrido
				// M10052401:	Modificaciones en la forma de acceso a bases de datos SQLite.
				if (await this.m_rs.EOF()) bClear = bReturn = true; // No se puede crear el objeto;	// No hay record actual
			} // Hay recorrido
			//
			// Solo limpia si hay que hacerlo
			if (bClear) this.ClearCurrentItem(true, true); // El que haya se jodio

			if (bReturn) return true;
			//
			// Si hay record actual, cargarlo
			obj = bClear ? null : this.m_pCurrentItem;
			if (obj == null) {
				// Crearlo
				// Si no ha encontrado un GUID válido pasar el ProgID
				// y que se explote pal carajo. Crear el objeto solo para cargarlo de BD
				obj = await this.DataColl.CreateObject(false);
				if (null == obj) {
					// Error
					// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
					////throw new XoneGenericException(-1818, "CXoneDataCollection::LoadCurrentItem ha fallado. No se puede crear un nuevo objeto para la colección '" + m_strName + "'");
					let sb = this.DataColl.GetMessage(
						XoneMessageKeys.SYS_MSG_COLL_LOADCURRITEMFAIL_01,
						"{0} failed. Cannot create new object from collection '{1}'"
					);
					sb = sb.replace("{0}", FunctionName);
					sb = sb.replace("{1}", this.DataColl.getName());
					throw new XoneGenericException(-1818, sb);
				} // Error
			} // Crearlo
			// ADD TAG Para poder tener la posicion del objeto antes del load
			// Asignar el contador a este objeto.
			obj.SetPropertyValue("MAP_SYS_ORDER", this.m_nBrowseOrder - 1);
			// Esto hay que hacerlo siempre...
			if (!this.m_bDeferredLoad) {
				// Normal
				// Si es una colección normal, cargar del recordset... si no, cargar remoto
				if (!(await obj.Load(this.m_rs))) return false;
			} // Normal // Diferido
			else obj.setIsCurrentItem(true);
			this.m_pCurrentItem = obj;

			// Sergio
			return true;
		} catch (ex) {
			// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
			////throw new XoneGenericException(-10089, "XoneDataCollection::LoadCurrentItem ha fallado. " + e.getMessage());
			let sMessage = ex.message;
			let sb = this.DataColl.GetMessage(XoneMessageKeys.SYS_MSG_GENERALFAIL, "{0} failed. ");
			sb = sb.replace("{0}", FunctionName);
			if (!TextUtils.isEmpty(sMessage)) {
				sb = sb.concat(sMessage);
			}
			throw new XoneGenericException(-10089, ex, sb);
		}
	}

	/**
	 * Limpia el elemento actual del recorrido. Se mantiene por una cuestión de compatibilidad, porque aquí no se llevan los totales corridos.
	 * @param ClearPrevious		TRUE si hay que limpiar la referencia al objeto anterior del recorrido.
	 * @param CopyPrevious		TRUE si hay que copiar el objeto actual del recorrido al elemento anterior.
	 */
	private ClearCurrentItem(ClearPrevious: boolean = false, CopyPrevious: boolean = false): void {
		this.m_pCurrentItem = null;
	}

	/**
	 * @return			Devuelve el Objeto del recorrido actual SB/EB o NULL si no hay recorrido activo o se ha llegado al final.
	 */
	public getCurrentItem(): XoneDataObject {
		return this.m_pCurrentItem;
	}
	//#endregion

	//#region
	/**
	 * Elimina un objeto de la colección dado su índice numérico
	 * @param Index				Indice del objeto en la colección (comenzando por cero)
	 * @return					Devuelve TRUE si la operación es correcta.
	 * @throws Exception
	 */
	public removeItem(key: number | string | XoneDataObject): boolean {
		let obj: XoneDataObject = null;
		if (this.m_lstCopyList != null) {
			this.m_lstCopyList = new Vector<XoneDataObject>();
			this.m_lstCopyList = null;
		}

		if (typeof key == "number" && key < this.m_lstOrderedList.length) {
			obj = this.m_lstOrderedList[key as number];
		} else if (typeof key == "string") {
			obj = this.m_lstObjectList.get(key as string);
		} else {
			obj = key as XoneDataObject;
		}
		if (!obj) return false;

		this.m_lstOrderedList.remove(obj);
		// Eliminar el objeto del mapa si es que existe
		let str = obj.GetObjectIdString();
		// F11081103: Protecciones al eliminar objetos de una colección por si no tienen ID.
		if (!TextUtils.isEmpty(str)) if (this.m_lstObjectList.containsKey(str)) this.m_lstObjectList.delete(str);
		// Actualizar el BL si se trata de una colección especial
		if (this.m_bIsSpecial) this.m_nBrowseLength = this.m_lstOrderedList.length;
		this.DataColl.setFull(false);
		return true;
	}
	//#endregion
}
