import { type } from "os";
import { Exception } from "../../Exceptions/Exception";
import IDataOptions from "../../Interfaces/IDataOptions";
import IResultSet from "../../Interfaces/IResultSet";
import { SqlParser } from "../../Parsers/SQL/SqlParser";
import NumberUtils from "../../Utils/NumberUtils";
import ObjUtils from "../../Utils/ObjUtils";
import StringUtils from "../../Utils/StringUtils";
import TextUtils from "../../Utils/TextUtils";
import XoneJSONConnection from "./XoneJSONConnection";

export default class XoneJSONResulset implements IResultSet {
	private m_nIterate: number;
	private m_parsed: SqlParser;
	private m_maxRows = 100;
	private m_data: any;
	private m_options: IDataOptions;
	private m_keys = [];
	private m_connection: XoneJSONConnection;
	private m_sentence: string;
	/**
	 *
	 */
	constructor(connection?: XoneJSONConnection, Sentence?: string, options?: IDataOptions) {
		this.m_connection = connection;
		this.m_nIterate = -1;
		this.m_sentence = Sentence;
		this.m_options = options;
		this.m_data = [];
		this.m_maxRows = this.m_data.length;
		this.m_keys = [];
		// this.m_parsed=new SqlParser("ROWID");
		// this.m_parsed.ParseSqlString(strCmd)
	}

	public async populate(options?: IDataOptions): Promise<IResultSet> {
		var result = await this.m_connection.fetchDataAsync(this.m_sentence, options || this.m_options);
		if (result.hasError == true) {
			throw new Exception(result.err);
		}
		this.m_data = Array.isArray(result.data) ? result.data : [];
		this.m_maxRows = this.m_data.length;
		this.m_keys = this.m_maxRows == 0 ? [] : Object.keys(this.m_data[0]);
		return this;
	}

	isResultset: boolean = true;

	getBytes(fld: string): number[] {
		return null;
	}
	getColumnCount(): number {
		return this.m_maxRows == 0 ? 0 : this.m_keys.length;
	}
	getColumnTypes(): number[] {
		return [1, 2, 3, 4, 5, 6, 7, 8];
	}
	getColumnName(ordinal: number): string {
		return this.m_maxRows == 0 ? 0 : this.m_keys[ordinal];
	}
	getData() {
		return this.m_data;
	}
	close(): void {
		this.m_nIterate = -1;
		this.m_data = null;
		this.m_maxRows = 0;
	}

	private resolverField(fld: string | number): string {
		if (typeof fld == "number") return this.m_keys[fld as number];
		return fld as string;
	}

	getValue(fld: string | number, nType: number) {
		switch (nType) {
			case 0: // Numero
				return NumberUtils.SafeToInt(this.m_data[this.m_nIterate][this.resolverField(fld)]);
			case 2:
				return NumberUtils.SafeToDouble(this.m_data[this.m_nIterate][this.resolverField(fld)]);
			case 3:
				return ObjUtils.SafeToDate(this.m_data[this.m_nIterate][this.resolverField(fld)]);
			default:
				return StringUtils.SafeToString(this.m_data[this.m_nIterate][this.resolverField(fld)]);
		}
	}
	getString(fld: string | number): string {
		return this.getValue(fld, 1) as string;
	}
	getInt(fld: string): number {
		return this.getValue(fld, 0) as number;
	}
	getDate(fld: string): Date {
		return this.getValue(fld, 3) as Date;
	}

	private get PageLen() {
		return this.m_options?.page?.length || -1;
	}

	private get PageCache() {
		return this.m_options?.page?.cache || -1;
	}

	public async next(): Promise<boolean> {
		// if (this.PageLen > 0) {
		//     if (this.PageCache > 0) {

		//     } else {
		//         await this.populate();
		//     }
		// }
		return ++this.m_nIterate < this.m_maxRows;
	}

	public async EOF(): Promise<boolean> {
		return this.m_nIterate >= this.m_maxRows;
	}
	public async moveTo(position: number): Promise<boolean> {
		return position == this.m_maxRows || (this.m_nIterate = position) == this.m_maxRows;
	}
}
