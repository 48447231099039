import { XoneDataCollection } from "../core/XoneDataCollection";
import { XoneDataObject } from "../core/XoneDataObject";
import IFieldProperties from "../Interfaces/IField";
import NumberUtils from "../Utils/NumberUtils";
import ObjUtils from "../Utils/ObjUtils";
import StringUtils from "../Utils/StringUtils";
import TextUtils from "../Utils/TextUtils";
import { Utils } from "../Utils/Utils";
import XmlNode from "../Xml/JSONImpl/XmlNode";
import XmlNodeList from "../Xml/JSONImpl/XmlNodeList";
import { UI_ATTRIBUTES, COLOR_ATTRIBUTES, FAST_ATTR_RESOLVER } from "./UIAttributes";

export default class UITransform {
	private static m_fastResolver = {};

	//#region Transformaciones para el UI PWA
	public static getLayout(Source: IFieldProperties, parent: XmlNode, visibility: number) {
		let result = <any>{ attributes: this.getPropAttributes(Source, parent) };
		if (parent.hasChildNodes()) {
			let type = Object.prototype.hasOwnProperty.call(UI_ATTRIBUTES, parent.getName()) ? "controls" : "others";
			result[type] = [];
			parent
				.getChildren()
				.forEach(
					(element) =>
						(!element.getName().equalsIgnoreCase(Utils.PROP_NAME) ||
							(NumberUtils.SafeToInt(
								Source.NodePropertyValue(Utils.PROP_NAME, element.getAttrValue(Utils.PROP_ATTR_NAME), Utils.PROP_ATTR_VISIBLE),
								1
							) &
								visibility) >
								0) &&
						result[type].push(this.getLayout(Source, element, visibility))
				);
		}
		return result;
	}

	public static async getLayoutAsync(Source: IFieldProperties, parent: XmlNode, visibility: number) {
		let result = <any>{ attributes: this.getPropAttributes(Source, parent) };
		if (parent.hasChildNodes()) {
			let type = Object.prototype.hasOwnProperty.call(UI_ATTRIBUTES, parent.getName()) ? "controls" : "others";
			result[type] = [];
			for (const element of parent.getChildren()) {
				(!element.getName().equalsIgnoreCase(Utils.PROP_NAME) ||
					(NumberUtils.SafeToInt(Source.NodePropertyValue(Utils.PROP_NAME, element.getAttrValue(Utils.PROP_ATTR_NAME), Utils.PROP_ATTR_VISIBLE), 1) &
						visibility) >
						0) &&
					result[type].push(this.getLayout(Source, element, visibility));
				await new Promise<void>((resolve) => setTimeout(() => resolve(), 1));
			}
		}
		return result;
	}

	public static getPropAttributes(Source: IFieldProperties, Value: string | XmlNode, type: string = Utils.PROP_NAME): any {
		let node: XmlNode;
		let PropName: string;
		if (typeof Value == "string") {
			node = Source.getNode(type, Utils.PROP_ATTR_NAME, Value);
			PropName = Value;
		} else {
			node = Value as XmlNode;
			PropName = node.getAttrValue(Utils.PROP_ATTR_NAME);
			type = node.getName();
		}
		let isPropNode = type.equalsIgnoreCase(Utils.PROP_NAME);
		if (ObjUtils.IsNothing(node)) return {};
		let result = <any>{ node: type };
		for (const key in UI_ATTRIBUTES[type]) {
			if (Object.prototype.hasOwnProperty.call(UI_ATTRIBUTES[type], key)) {
				const element = UI_ATTRIBUTES[type][key];
				try {
					const AttrValue = this.developValue(Source, isPropNode, type, PropName, element); //
					if (!TextUtils.isEmpty(AttrValue)) {
						result[key] = this.normailzeWebAttribute(key, AttrValue);
					}
				} catch (e) {
					console.error(e);
				}
			}
		}
		return result;
	}

	public static developValue(Source: IFieldProperties, isPropNode: boolean, PropType: string, PropName: string, element: any): any {
		if (typeof element == "string") return this.getValue(Source, isPropNode, PropType, PropName, element);
		let retDefault = this.evaluateMacro(Source, "default" in element ? element["default"] : "");
		let retValue = "";
		if ("value" in element) {
			if (Array.isArray(element["value"])) {
				retValue = this.getArrayValue(Source, isPropNode, PropType, PropName, element["value"]);
			} else {
				retValue = this.getValue(Source, isPropNode, PropType, PropName, element["value"]);
			}
		}
		return TextUtils.isEmpty(retValue) ? retDefault : retValue;
	}

	private static getArrayValue(Source: IFieldProperties, isPropNode: boolean, PropType: string, PropName: string, element: Array<string>) {
		let retValue = "";
		for (let i = 0; i < element.length && TextUtils.isEmpty((retValue = this.getValue(Source, isPropNode, PropType, PropName, element[i]))); i++) {
			// if (!TextUtils.isEmpty(retValue=this.getValue(Source,node,PropType,PropName,element[i])))
			//   break;
		}
		return retValue;
	}

	private static getValue(Source: IFieldProperties, isPropNode: boolean, PropType: string, PropName: string, AttrName: any) {
		return isPropNode ? Source.FieldPropertyValue(PropName, AttrName) : Source.NodePropertyValue(PropType, PropName, AttrName);
	}

	private static normalizeWebColor(value: string): string {
		if (!value.startsWith("##") && value.startsWith("#") && value.length > 7) {
			return "#" + value.substr(value.length - 6, 6) + value.substr(1, value.length - 7).padStart(2, "0");
		}
		return value;
	}

	private static normailzeWebAttribute(AttrName: string, value: string): string {
		if (COLOR_ATTRIBUTES.indexOf(AttrName) >= 0) {
			return this.normalizeWebColor(value);
		}
		return value;
	}
	private static evaluateMacro(Source: IFieldProperties, macro: string) {
		if (!macro.startsWith("##")) return macro;
		if (macro.equals("##COMPANYCOLOR##")) return Source.getOwnerApp().getAppNode().attrs.companycolor || Utils.DEFAULT_BGCOLOR;
		if (macro.equals("##COMPANYFORECOLOR##")) return Source.getOwnerApp().getAppNode().attrs.forecolor || Utils.DEFAULT_FORECOLOR;
		return macro;
	}
	//#endregion

	//#region FieldPropertyValue

	private static resolvedAttrName(AttrName: string): string {
		if (FAST_ATTR_RESOLVER.hasOwnProperty(AttrName)) return FAST_ATTR_RESOLVER[AttrName];
		return AttrName;
	}

	public static setLayoutFieldPropertyValue(value: any, node: string, name: string, attrXoneName: string, attrValue: string): boolean {
		const attrs = value?.attributes;
		const attrName = UITransform.resolvedAttrName(attrXoneName);
		if (attrs && attrs.node == node && attrs.name == name) {
			if (attrs.hasOwnProperty(attrName)) attrs[attrName] = attrValue;
			else {
				// TODO: Luis esto ver con alejandro como se agrega una nueva
				attrs[attrName] = attrValue;
			}
			return true;
		} else if (value?.controls && Array.isArray(value.controls)) {
			for (let index = 0; index < value.controls.length; index++) {
				if (UITransform.setLayoutFieldPropertyValue(value.controls[index], node, name, attrXoneName, attrValue)) return true;
			}
		}
		return false;
	}
	//#endregion
}
