<template>
	<div
		v-if="(!attributes.imgChecked && !attributes.imgUnchecked) || attributes.isRadio"
		:style="{
			padding: '0',
			//height: 'var(--label-height)',
			// Align
			alignItems: attributes.labelAlign,
			'--checkbox-size': checkboxSize ? `${checkboxSize}px` : `calc(4px + ${attributes.fontSize})`,
		}"
	>
		<input
			v-if="!attributes.isRadio"
			ref="checkboxElement"
			:disabled="isDisabled"
			:checked="isChecked"
			@change="onCheckboxCheckedChange()"
			type="checkbox"
		/>

		<input
			v-else
			ref="radioElement"
			:disabled="isDisabled"
			type="radio"
			:name="`${attributes.radioGroup}-${breadcrumbId}${objectIndex}`"
			:prop="attributes.name"
			:checked="isChecked"
			@change="onRadioCheckedChange()"
		/>
	</div>
	<button
		v-else
		:disabled="isDisabled"
		@click="onCheckboxCheckedChange()"
		:style="{
			// Size
			width: imageSize.width ? `${imageSize.width}px` : (attributes.width !== undefined && '100%') || attributes.fieldSize,
			height: imageSize.height ? `${imageSize.height}px` : (controlHeight && `${controlHeight - 11}px`) || '30px',
			// Background Image
			backgroundImage: `url(${
				isDisabled || attributes.locked
					? isChecked
						? backgroundImageCheckedDisabled || backgroundImageChecked
						: backgroundImageUncheckedDisabled || backgroundImageUnchecked
					: isChecked
					? backgroundImageChecked
					: backgroundImageUnchecked
			})`,
			// Disableedit / Locked appearance
			opacity: changeOpacity && attributes.disableEdit && attributes.disableEdit !== '' && isDisabled ? 0.4 : 1,
		}"
	></button>
</template>

<script>
import { Ref, ComputedRef, PropType, computed, ref, reactive, inject, onMounted } from "vue";

import { xoneAttributesHandler, PropAttributes } from "../../../composables/XoneAttributesHandler";
import { XoneDataObject } from "../../../composables/appData/core/XoneDataObject";
import { getAppPath, getImagePath } from "../../../composables/helperFunctions/ImageHelper";

export default {
	props: {
		/** xoneDataObject
		 * @type {PropType<XoneDataObject>}
		 */
		xoneDataObject: { type: Object, required: true },

		/** attributes
     @type {PropType<PropAttributes>} 
     */
		attributes: { type: Object, required: true },

		isDisabled: { type: Boolean, required: true },

		controlWidth: { type: Number, default: 0 },

		controlHeight: { type: Number, default: 0 },

		checkboxSize: { type: Number, default: null },
	},
	setup(props) {
		/** @type {Ref<HTMLInputElement>} */
		const checkboxElement = ref();

		/** @type {Ref<HTMLInputElement>} */
		const radioElement = ref();

		const breadcrumbId = inject("breadcrumbId");

		/**
		 * Get editInRow="false" if prop is in contents
		 * @type {import('../../../composables/AppDataHandler').Objectinfo}
		 */
		const objectInfo = inject("objectInfo");

		const objectIndex = objectInfo.isContents ? objectInfo.recordIndex : "";

		/**
		 * on value change
		 */
		const onChange = async () => {
			try {
				if (objectInfo?.editInRow && objectInfo.autosave) {
					await props.xoneDataObject.save();
				}
			} catch (ex) {
				xoneUI.showSnackbar({
					text: ex,
					color: "red",
				});
			}
		};

		const onCheckboxCheckedChange = () => {
			let value = props.xoneDataObject.model[props.attributes.name];
			if (props.attributes.bit || props.attributes.bit == 0) value = props.xoneDataObject.GetBitmappedProperty(props.attributes.name);
			props.xoneDataObject.changeModelValue(props.attributes.name, !value ? 1 : 0);
			onChange();
		};

		const onRadioCheckedChange = () => {
			props.xoneDataObject.changeModelValue(props.attributes.name, radioElement.value.checked ? 1 : 0);
			document.querySelectorAll(`[name='${props.attributes.radioGroup}-${breadcrumbId}${objectIndex}']`).forEach(
				/** @type {HTMLInputElement} */ (e) => {
					if (e === radioElement.value) return;
					props.xoneDataObject.changeModelValue(e.getAttribute("prop"), 0);
				}
			);
			onChange();
		};

		/**
		 * Scale Factor
		 * @type {{widthFactor: ComputedRef<number>, heightFactor: ComputedRef<number>}}
		 */
		const { widthFactor, heightFactor } = inject("scaleFactor");

		const imageSize = reactive({
			width: computed(() => xoneAttributesHandler.getScaledSize(props.attributes.imgWidth, props.containerWidth, widthFactor.value)),
			height: computed(() =>
				xoneAttributesHandler.getScaledSize(
					props.attributes.imgHeight, // offset height
					props.containerHeight,
					heightFactor.value
				)
			),
		});

		const backgroundImageChecked = ref(`${getAppPath()}/icons/${props.attributes.imgChecked}`);
		const backgroundImageUnchecked = ref(`${getAppPath()}/icons/${props.attributes.imgUnchecked}`);
		const backgroundImageCheckedDisabled = ref(`${getAppPath()}/icons/${props.attributes.imgCheckedDisabled}`);
		const backgroundImageUncheckedDisabled = ref(`${getAppPath()}/icons/${props.attributes.imgUncheckedDisabled}`);

		onMounted(() => {
			getImagePath(props.attributes.imgChecked, "icons").then((value) => (backgroundImageChecked.value = value));
			getImagePath(props.attributes.imgUnchecked, "icons").then((value) => (backgroundImageUnchecked.value = value));
			getImagePath(props.attributes.imgCheckedDisabled, "icons").then((value) => (backgroundImageCheckedDisabled.value = value));
			getImagePath(props.attributes.imgUncheckedDisabled, "icons").then((value) => (backgroundImageUncheckedDisabled.value = value));

			if (backgroundImageCheckedDisabled.value || backgroundImageUncheckedDisabled.value) changeOpacity.value = false;
			if (props.xoneDataObject.model[props.attributes.name] !== undefined && (props.attributes.bit || props.attributes.bit == 0)) {
				props.xoneDataObject.changeModelValue(props.attributes.name, isChecked.value ? 1 : 0);
			}
		});

		const changeOpacity = ref(true);

		const isChecked = computed(() => {
			// Campo enlazado a un bit, lo resolvemos
			if (props.xoneDataObject.model[props.attributes.name] !== undefined && (props.attributes.bit || props.attributes.bit == 0)) {
				return props.xoneDataObject.GetBitmappedProperty(props.attributes.name);
			}
			// Comprobamos si el valor es 1
			return props.xoneDataObject.model[props.attributes.name] == 1;
		});

		return {
			checkboxElement,
			radioElement,
			breadcrumbId,
			objectIndex,
			imageSize,
			backgroundImageChecked,
			backgroundImageUnchecked,
			onChange,
			onCheckboxCheckedChange,
			onRadioCheckedChange,
			backgroundImageCheckedDisabled,
			backgroundImageUncheckedDisabled,
			changeOpacity,
			isChecked,
		};
	},
};
</script>

<style scoped>
div,
column {
	display: flex;
	flex-direction: column;
	justify-content: var(--frame-align-column);
}

input[type="checkbox"],
input[type="radio"] {
	margin: 3px 5px 3px 0;
	flex-shrink: 0;
	flex-grow: 0;
	align-self: flex-start;
	cursor: pointer;
	width: var(--checkbox-size);
	height: var(--checkbox-size);
}

button {
	cursor: pointer;
	outline: none;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	border: none;
	background-color: transparent;
	-webkit-transition: background-image 0.3s;
	transition: background-image 0.3s;
}
</style>
