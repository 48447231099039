<template>
	<div :id="`map-${breadcrumbId}${objectIndex}`" class="xone-map" @click="onMapClick">
		<div class="xone-map-markers" @mousewheel="onMouseWheel">
			<div
				v-if="showMarkerButton && showPoisButton"
				:style="{
					'--image-layers': `url(${appPath}/assets/layers.png) no-repeat`,
				}"
				class="xone-marker-button"
			>
				<button @click="onMarkerButtonClick"></button>
			</div>
			<div v-if="showMarkerButton && showPoisButton && attributes.showPois" class="xone-marker-button xone-pois-button">
				<button @click="onPoisButtonClick">POIS</button>
			</div>

			<transition name="slide-fade">
				<div class="xone-markers-list" v-show="showLayers" :style="{ maxHeight: `${controlHeight - 25}px` }">
					<a
						v-for="(layer, index) in keyLayers"
						:key="`map-${index}`"
						:class="{ 'xone-selected-marker': checkIsLayerSelected(layer) }"
						@click="changeMapLayer(layer, $event)"
						><img />{{ layer }}</a
					>
				</div>
			</transition>

			<transition name="slide-fade">
				<div class="xone-markers-list" v-show="showPois" :style="{ maxHeight: `${controlHeight - 25}px;` }">
					<input placeholder="search..." v-model="poisSearch" @click="onSearchClick" />
					<a v-for="(poi, index) in filterPois" :key="`pois-${index}`" style="justify-content: flex-start" @click="onMarkerClick($event, index)">
						<img :src="poi.icon" />
						<div>{{ poi.description }}</div></a
					>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import { computed, inject, onMounted, onUnmounted, PropType, ref, Ref, watch } from "vue";

import { PropAttributes } from "../../../composables/XoneAttributesHandler";
import { XoneDataObject } from "../../../composables/appData/core/XoneDataObject";
import { XoneDataCollection } from "../../../composables/appData/core/XoneDataCollection";
import { getDefaultMapLayer, getMapLayers, loadLeaflet, replaceLayerName } from "../../../composables/helperFunctions/MapHelper";
import { XoneControl, XoneView } from "../../../composables/XoneViewsHandler";
import { generateUniqueId } from "../../../composables/helperFunctions/StringHelper";
import { normalizeWebColor } from "../../../composables/helperFunctions/ColorHelper";
import { getAppPath, getImagePathAndSize } from "../../../composables/helperFunctions/ImageHelper";
import XmlNode from "../../../composables/appData/Xml/JSONImpl/XmlNode";

export default {
	name: "Map",
	props: {
		/**
		 * xoneDataObject
		 * @type {PropType<XoneDataObject>}
		 * */
		xoneDataObject: { type: Object, required: true },
		/**
		 * attributes
		 * @type { PropType<PropAttributes>}
		 */
		attributes: { type: Object, default: null, required: true },
		controlWidth: { type: Number, default: 0 },
		controlHeight: { type: Number, default: 0 },
	},
	setup(props) {
		// const api = `https://api.locationiq.com/v1/autocomplete.php?key=47616557e9fb47&q=santander&limit=5`;

		// const defaultIconUrl = "/assets/marker-icon.png";
		// const defaultShadowUrl = "/assets/marker-shadow.png";

		/**
		 * breadcrumbId
		 * @type {string}
		 */
		const breadcrumbId = inject("breadcrumbId");

		/**
		 * objectInfo
		 * @type {import('../../../composables/AppDataHandler').Objectinfo}
		 */
		const objectInfo = inject("objectInfo");

		const objectIndex = objectInfo.isContents ? objectInfo.recordIndex : "";

		// tiles provider for leaflet
		const mapLayers = ref({});

		/** @type {ComputedRef} */
		const keyLayers = computed(() =>
			Object.entries(mapLayers.value)
				.filter(([, value]) => !value.onlyLabel && value.type === (props.attributes.viewMode === "openstreetmap" ? "OSM" : "google"))
				.map(([key]) => key)
		);
		// Map
		let map;

		// Gestionamos el resize de la pantalla
		let resizeTimeout;
		const resizeMap = () => {
			if (map && map.invalidateSize) {
				if (resizeTimeout) clearTimeout(resizeTimeout);
				resizeTimeout = setTimeout(() => map.invalidateSize(), 500);
			}
		};

		watch(
			() => props.controlWidth,
			() => resizeMap()
		);
		watch(
			() => props.controlHeight,
			() => resizeMap()
		);

		let markersCluster;
		// Markers
		let markers = [];
		const pois = ref([]);
		const filterPois = computed(() => pois.value.filter((e) => e.description?.toLowerCase().includes(poisSearch.value.toLowerCase())));

		// Lines
		const polyLines = new Map();

		// Polygons
		const polygons = new Map();

		// Routes
		const routes = new Set();

		// Draw line
		const drawLine = (name, color, lineStyle, ...pointList) => {
			const points = [];
			for (let i = 0; i < pointList.length; i += 2) {
				points.push(new L.LatLng(pointList[i], pointList[i + 1]));
			}
			let dashArray = null;
			if (lineStyle === "dashed") dashArray = "10, 15";
			else if (lineStyle === "dotted") dashArray = "1, 10";
			// else if (lineStyle === "mixed") dashArray = "10, 5, 1, 10";
			const firstpolyline = new L.Polyline(points, {
				color: normalizeWebColor(color),
				weight: 5,
				opacity: 0.5,
				smoothFactor: 1,
				dashArray,
			});
			if (polyLines.get(name)) name = generateUniqueId();
			polyLines.set(name, firstpolyline);
			firstpolyline.addTo(map);
		};

		// Draw area
		const drawArea = ({ id, data, color, fillcolor, pattern, width }) => {
			const points = [];
			data.forEach((e) => {
				const dataCoords = e.split(",").map((e) => Number(e));
				points.push(new L.LatLng(dataCoords[0], dataCoords[1]));
			});
			let dashArray = null;
			if (pattern === "dashed") dashArray = "10, 15";
			else if (pattern === "dotted") dashArray = "1, 10";

			const polygon = L.polygon(points, {
				color: normalizeWebColor(color),
				fillColor: normalizeWebColor(fillcolor),
				weight: width,
				dashArray,
			}).addTo(map);

			if (polygons.get(id)) id = generateUniqueId();
			polygons.set(id, polygon);
			// polygon.bindPopup("I am a polygon.");
			// polygon.bindTooltip("I am a polygon.").openTooltip();
		};

		// Draw route
		const drawRoute = (Args) => {
			const route = L.Routing.control({
				waypoints: [L.latLng(Args.sourceLatitude, Args.sourceLongitude), L.latLng(Args.destinationLatitude, Args.destinationLongitude)],
				routeWhileDragging: false,
				showAlternatives: false,
			});
			routes.add(route);
			route.addTo(map);
		};

		// Remove markers
		const removeMarkers = () => {
			if (markersCluster) markers.forEach((e) => markersCluster.removeLayer(e));
			else markers.forEach((e) => map.removeLayer(e));
			markers = [];
			pois.value = [];
		};

		// Remove lines
		const clearLines = () => {
			polyLines.forEach((e) => map.removeLayer(e));
			polyLines.clear();
		};

		// Remove lines
		const clearAreas = () => {
			polygons.forEach((e) => map.removeLayer(e));
			polygons.clear();
		};

		/**
		 * Contents
		 * @type {Ref<XoneDataCollection>}
		 */
		const contents = ref();

		/**
		 * xoneView
		 * @type {XoneView}
		 */
		const xoneView = inject("xoneView");

		/**
		 * contents data
		 * @type {Ref<Array<XoneDataObject>>}
		 */
		const data = ref([]);

		/**
		 * Refresh map
		 */
		const refresh = async () => {
			// Load map
			loadMap();

			removeMarkers();

			// Get contents
			if (!contents.value) contents.value = await props.xoneDataObject.getContents(props.attributes.contents);
			doInitAttributes();
			// Load contents data
			// LoadAll
			if (contents.value.length === 0) await contents.value.loadAll(false);

			data.value = [];
			for (let i = 0; i < contents.value.length; i++) {
				/**
				 * rowDataObject
				 * @type {XoneDataObject}
				 */
				const rowDataObject = await contents.value.get(i);
				data.value.push(rowDataObject);
				// Execute Nodes
				rowDataObject
					.ExecuteNode("before-edit")
					.then(() => rowDataObject.ExecuteNode("load"))
					.then(() => rowDataObject.ExecuteNode("after-edit"))
					.catch(console.error);
			}

			drawMarkers(true);
		};

		/** @type {string} */
		let markerIconField;
		/** @type {string} */
		let markerDescriptionField;

		const doInitAttributes = () => {
			/** @type {{m_xmlNode:XmlNode}} */
			const { m_xmlNode } = contents.value;
			m_xmlNode.SelectNodes("prop").forEach((/** @type {XmlNode} */ e) => {
				if (e.getAttrValue("mapview-marker-icon") === "true") markerIconField = e.getAttrValue("name");
				if (e.getAttrValue("mapview-description") === "true") markerDescriptionField = e.getAttrValue("name");
				// if (e.getAttrValue("show-in-marker") === "true") markerDescriptionField = e.getAttrValue("name");
				// if (e.getAttrValue("show-pois") === "true") markerDescriptionField = e.getAttrValue("name");
			});
		};

		/**
		 * Refresh map markers
		 */
		const drawMarkers = async (withFitBounds = false) => {
			removeMarkers();
			// create bounds array
			const bounds = [];
			for (let e of data.value) {
				if (!e.LATITUD || !e.LONGITUD) continue;
				// icon variables
				let iconUrl = `${getAppPath()}/assets/marker-icon.png`;
				let shadowUrl = `${getAppPath()}/assets/marker-shadow.png`;
				let iconSize = [25, 41];
				let iconAnchor = [12, 41];
				const markerIcon = markerIconField ? await getImagePathAndSize(e[markerIconField]) : null;
				// custom marker icon
				if (markerIcon) {
					let { width, height } = markerIcon.imgSize;
					// reducimos el tamaño de la imagen
					while (width > 75 || height > 75) {
						width = width * 0.9;
						height = height * 0.9;
					}
					iconUrl = markerIcon.path;
					iconSize = [width, height];
					iconAnchor = [width / 2, height / 2];
					shadowUrl = null;
				}
				const icon = L.icon({
					iconUrl,
					shadowUrl,
					iconSize,
					iconAnchor,
				});
				// push coordinates into bounds
				bounds.push([e.LATITUD, e.LONGITUD]);
				// Create marker
				const marker = L.marker([e.LATITUD, e.LONGITUD], { icon })
					// on click execute selecteditem node
					.on("click", () => e.ExecuteNode("selecteditem").catch(console.error));
				if (props.attributes.clusterMarkers) markersCluster.addLayer(marker);
				else marker.addTo(map);
				// marker description
				if (markerDescriptionField && props.attributes.showInMarker)
					marker.bindTooltip(e[markerDescriptionField], {
						permanent: true,
						offset: [iconSize[0] / 2, 0],
					});
				markers.push(marker);
				pois.value.push({
					description: e[markerDescriptionField] ?? bounds.length.toString(),
					icon: iconUrl,
				});
			}
			// fit bounds
			if (withFitBounds && bounds.length !== 0) {
				map.fitBounds(bounds);
				map.flyToBounds(bounds, { animate: true, duration: 0.3 });
			}
			if (bounds.length == 0) map.setView([0, 0], 3);

			if (props.attributes.clusterMarkers) map.addLayer(markersCluster);
		};

		// Clear layers
		const clearLayers = () => {
			Object.keys(map._layers).forEach((key) => {
				try {
					map.removeLayer(map._layers[key]);
				} catch {}
			});
		};

		// Clear routes
		const clearRoutes = () => routes.forEach((e) => map.removeControl(e));

		/**
		 * @type {Object}
		 */
		let currentMapTileLayer;

		/**
		 * @type {string}
		 */
		let currentMapLayerName = getDefaultMapLayer(props.attributes);

		const changeMapLayer = (/** @type {string}*/ layer, /** @type {MouseEvent} */ e = null) => {
			if (e) e.stopPropagation();

			layer = replaceLayerName(layer);
			currentMapLayerName = layer;

			if (currentMapTileLayer) map.removeLayer(currentMapTileLayer);

			currentMapTileLayer = mapLayers.value[layer].layer;

			if (!currentMapTileLayer) currentMapTileLayer = Object.values(mapLayers.value).forEach((e) => e.title === layer);

			// Add new layer
			currentMapTileLayer.addTo(map);

			// Set zoom
			if (map._zoom > mapLayers.value[layer].layer.options.maxZoom) map.setZoom(mapLayers.value[layer].layer.options.maxZoom);

			// Refresh Markers
			drawMarkers();

			showLayers.value = false;
		};

		/**
		 * Load Map
		 */
		const loadMap = () => {
			if (!map) map = L.map(`map-${breadcrumbId}${objectIndex}`).setView([43.454518, -3.851194], 2);
			else {
				clearRoutes();
				clearLayers();
			}
			currentMapTileLayer = mapLayers.value[getDefaultMapLayer(props.attributes)].layer;
			currentMapTileLayer.addTo(map);
			resizeMap();
		};

		onMounted(async () => {
			await loadLeaflet();

			if (props.attributes.clusterMarkers) markersCluster = L.markerClusterGroup();

			// map Layers
			mapLayers.value = getMapLayers(L, props.attributes);

			//
			// Add control to view
			const xoneControl = new XoneControl(props.attributes.name);

			// Refresh
			xoneControl.refresh = refresh;
			// Draw Line
			xoneControl.drawLine = drawLine;
			// Clear Area
			xoneControl.clearArea = (Args) => map.removeLayer(polygons.get(Args));
			// Clear all Areas
			xoneControl.clearAllAreas = clearAreas;
			// Clear line
			xoneControl.clearLine = (Args) => map.removeLayer(polyLines.get(Args));
			// Clear all lines
			xoneControl.clearAllLines = clearLines;
			// CLear all routes
			xoneControl.clearAllRoutes = clearRoutes;
			// CLear route
			xoneControl.clearRoute = clearRoutes;
			// Draw Area
			xoneControl.drawArea = drawArea;
			// Draw Route
			xoneControl.drawRoute = drawRoute;
			xoneControl.routeTo = drawRoute;
			// Remove area
			xoneControl.removeArea = (area) => polygons.has(area) && map.removeLayer(polygons.get(area));
			// Zoom to
			xoneControl.zoomTo = (...Args) => {
				map.flyTo([Args[0], Args[1]], 18, { duration: 1 });
			};
			// Set Map Type
			xoneControl.setMapType = changeMapLayer;
			// Zoom to bounds
			xoneControl.zoomToBounds = (Args) => {
				// map.fitBounds(Args.map((e) => e.split(",").map((e) => Number(e))));
				map.flyToBounds(
					Args.map((e) => e.split(",").map((e) => Number(e))),
					{ animate: true, duration: 1 }
				);
			};
			// TODO: implementar estos 3 métodos
			xoneControl.isUserLocationEnabled = () => {};
			xoneControl.enableUserLocation = () => {};
			xoneControl.zoomToMyLocation = () => {};

			xoneView.addControl(xoneControl);

			//
			// Refresh contents
			refresh();
		});
		onUnmounted(() => {
			if (contents.value) contents.value.clear();
		});

		const showMarkerButton = ref(true);
		const showPoisButton = ref(true);
		const poisSearch = ref("");
		const showLayers = ref(false);
		const showPois = ref(false);

		watch(
			() => showLayers.value,
			(newValue) => {
				if (newValue) showMarkerButton.value = false;
				else setTimeout(() => (showMarkerButton.value = true), 400);
			}
		);

		watch(
			() => showPois.value,
			(newValue) => {
				if (newValue) showPoisButton.value = false;
				else setTimeout(() => (showPoisButton.value = true), 400);
			}
		);

		return {
			keyLayers,
			breadcrumbId,
			objectIndex,
			showLayers,
			showPois,
			changeMapLayer,
			showMarkerButton,
			showPoisButton,
			poisSearch,
			pois,
			filterPois,
			onMapClick: () => {
				showLayers.value = false;
				showPois.value = false;
			},
			onSearchClick: (/** @type {MouseEvent} */ e) => e.stopPropagation(),
			onMarkerClick: async (/** @type {MouseEvent} */ e, /** @type {number} */ index) => {
				e.stopPropagation();
				const obj = await contents.value.get(index);
				if (!obj) return;
				obj.ExecuteNode("selecteditem").catch(console.error);
			},
			onMouseWheel: (/** @type{ MouseEvent} */ e) => e.stopPropagation(),
			onMarkerButtonClick: async (/** @type {MouseEvent} */ e) => {
				showLayers.value = !showLayers.value;
				showPois.value = false;
				e.stopPropagation();
			},
			onPoisButtonClick: async (/** @type {MouseEvent} */ e) => {
				showPois.value = !showPois.value;
				showLayers.value = false;
				e.stopPropagation();
			},
			checkIsLayerSelected: (/** @type {string} */ layer) => replaceLayerName(layer) === replaceLayerName(currentMapLayerName),
			appPath: getAppPath(),
		};
	},
};
</script>

<style scoped>
.xone-map {
	position: relative;
	height: 100vh;
	width: 100%;

	width: var(--contents-width);
	height: var(--contents-height);
	max-height: var(--contents-max-height);
}
.xone-map-markers {
	display: flex;
	flex-direction: column;
	position: absolute;
	right: 5px;
	margin-top: 5px;
	z-index: 999;
	pointer-events: all;
	padding: 5px;
}

.xone-selected-marker {
	font-weight: bold;
}

.xone-marker-button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	border-radius: 2px;
	border: 2px solid rgb(204, 204, 204);
	background-color: #fff;
	animation: slideLeft 0.2s;
	margin-bottom: 3px;
	/* box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14),
    0 1px 10px rgba(0, 0, 0, 0.12); */
}
.xone-pois-button > button {
	font-size: 9px !important;
	text-align: center;
}
.xone-marker-button:hover {
	background: #f4f4f4;
}

.xone-marker-button button {
	cursor: pointer;
	display: block;
	background: var(--image-layers);
	background-position: center;
	padding: 5px;
	border: none;
	width: 30px;
	height: 30px;
	background-size: 75%;
}

.xone-markers-list {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	animation: slideLeft 0.3s;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: #fff;
	border-radius: 2px;
	margin-left: 5px;
	border: 2px solid rgb(204, 204, 204);

	/* box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14),
    0 1px 10px rgba(0, 0, 0, 0.12); */
	pointer-events: all;
}

input {
	padding: 3px;
}

/* input {
	max-width: 100px;
} */

a {
	cursor: pointer;
	background-color: white;
	color: black !important;
	padding: 10px;
	text-align: justify;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	pointer-events: all;
	display: flex;
	justify-content: center;
	align-items: center;
}

a > img {
	height: 22px;
}
a > div {
	padding: 0 3px;
}

a:hover {
	background-color: #f4f4f4;
}

a:link {
	text-decoration: none;
}

.slide-fade-enter-active {
	transition: all 0.2s ease;
}
.slide-fade-leave-active {
	transition: all 0.2s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
	transform: translateX(100px);
	opacity: 0;
}
</style>
